<template>
  <!--This is the resources page-->
  <q-scroll-area style="height: calc(100vh - 150px)">
    <!--find an arborist-->
    <q-toolbar class="text-center">
      <q-space></q-space>
      <q-btn flat color="primary"
        >FAQ
        <q-menu>
          <div class="q-ma-md">
            <p class="text-body1 text-blue-grey-9">
              Frequently Asked Questions
            </p>
            <q-separator spaced></q-separator>
            <p class="text-subtitle2 q-mb-none text-primary">
              What is Healthy Trees, Healthy Cities?
            </p>
            <p>
              Healthy Trees, Healthy Cities (HTHC) is an urban tree health
              monitoring initiative which supports researchers, managers, and
              civic ecologists in the protection of trees and forests in
              communities across the U.S. HTHC provides tools such as online
              training videos and the HTHC field tool and web-based project
              management dashboard to monitor and prioritize stewardship and
              management of trees.
            </p>
            <p class="text-subtitle2 text-primary q-mb-none">
              What is the HTHC web app?
            </p>
            <p>
              The HTHC field tool is a free and user-friendly progressive web
              app that allows users to collect and access tree inventory and
              monitoring data, both in the field or in the office. The field
              tool uses four modules that can be used to manage key aspects of
              tree stewardship while in the field: 1) Add a Tree (basic
              inventory), 2) Tree Care (stewardship tracker), 3) Health Check
              (health assessment), and 4) Pest Detection (signs of
              insects/diseases).
            </p>

            <p class="text-subtitle2 q-mb-none text-primary">
              Why is tree monitoring important?
            </p>
            <p>
              The nation’s trees and forests are one of our greatest assets –
              especially in cities where they provide myriad benefits for
              communities (i.e. reducing harm air pollutants, mitigating summer
              air temperatures, etc.). Accordingly, urban forest managers must
              maintain a healthy urban tree canopy to ensure those benefits are
              experienced by all well into the future.
            </p>

            <p class="text-subtitle2 q-mb-none text-primary">
              How is the data used?
            </p>
            <p>
              The data gathered through these efforts have both local and
              national applications: locally, managers and stewards use the data
              to prioritize the care of stressed trees and intervene to restore
              health; nationally, USDA Forest Service researchers study the
              effects of the urban environment on trees, and the impact healthy
              trees have on communities.
            </p>

            <p class="text-subtitle2 q-mb-none text-primary">
              Who can use HTHC?
            </p>
            <p>
              While the majority of HTHC users include forestry managers and
              tree stewards, we encourage everyone to become civic ecologists
              and use HTHC tools to contribute to the health of trees in their
              communities.
            </p>

            <p class="text-subtitle2 q-mb-none text-primary">
              How many people currently use the HTHC web app?
            </p>
            <p>
              Nearly 800 people have used the original HTHC app and we
              anticipate that number will grow when the new and improved field
              tool and dashboard launch.
            </p>

            <p class="text-subtitle2 q-mb-none text-primary">
              How much does it cost?
            </p>
            <p>
              HTHC is free to all. Who are the project partners that support
              HTHC? HTHC is coordinated and supported by The Nature Conservancy
              and the USDA Forest Service
            </p>
          </div>
        </q-menu></q-btn
      >
      <q-btn
        flat
        color="primary"
        @click="
          this.$store.commit('updateShowHelp', {
            url: '/resources/publications/guides/HTHC Fact Sheet 2023.pdf',
            filename: 'HTHC Fact Sheet',
          })
        "
        >ABOUT</q-btn
      >
    </q-toolbar>
    <q-item
      class="q-ml-lg q-mr-lg q-mt-sm bg-blue-grey-1"
      clickable
      @click="openLink"
    >
      <q-item-section avatar>
        <q-avatar square>
          <img src="/resources/isa-logo.png" />
        </q-avatar>
      </q-item-section>
      <q-item-section class="text-left">
        <q-item-label class="text-primary">Find an Arborist</q-item-label>
        <q-item-label class="text-caption"
          >Search for an arborist by name or arborists in a given location.
          Clicking here will open a new tab in your web browser.</q-item-label
        >
      </q-item-section>
    </q-item>
    <!--resources tree-->
    <div
      class="q-pa-md q-gutter-sm"
      :style="$q.screen.lt.sm ? 'padding-bottom:100px' : ''"
    >
      <div class="row">
        <div class="col-md-4 col-sm-12 col-xs-12 q-gutter-sm">
          <q-tree
            ref="tree"
            accordion
            :nodes="this.resourcesTree"
            node-key="label"
            selected-color="secondary"
            class="text-body1 q-ml-lg"
            no-connectors
            dense
            v-model:expanded="expanded"
          >
            <template v-slot:default-header="prop">
              <div v-if="prop.node.url" class="q-pl-xs">
                <q-item
                  dense
                  clickable
                  @click="
                    this.clearAll();
                    this.selectedVideo = prop.node.url;
                    this.selectedTitle = prop.node.label;
                  "
                >
                  <q-item-section side class="q-pr-xs">
                    <q-icon name="play_circle" color="secondary"></q-icon>
                  </q-item-section>
                  <q-item-section>
                    {{ prop.node.label }}
                  </q-item-section>
                </q-item>
              </div>
              <div v-if="prop.node.files" class="q-pl-xs">
                <q-item
                  dense
                  clickable
                  @click="
                    this.clearAll();
                    this.selectedPublication = prop.node;
                  "
                >
                  <q-item-section side class="q-pr-xs">
                    <q-icon name="article" color="secondary"></q-icon>
                  </q-item-section>
                  <q-item-section>
                    {{ prop.node.label }}
                  </q-item-section>
                </q-item>
              </div>
              <div v-if="prop.node.pdf" class="q-pl-xs">
                <q-item
                  dense
                  clickable
                  @click="
                    this.$store.commit('updateShowHelp', {
                      url: prop.node.pdf,
                      filename: prop.node.filename,
                    })
                  "
                >
                  <q-item-section side class="q-pr-xs">
                    <q-icon name="fa-solid fa-file-pdf" color="red"></q-icon>
                  </q-item-section>
                  <q-item-section>
                    {{ prop.node.label }}
                  </q-item-section>
                </q-item>
              </div>
              <div v-if="prop.node.children">{{ prop.node.label }}</div>
            </template>
          </q-tree>
        </div>
        <div
          v-if="this.selectedVideo"
          class="col-md-8 col-sm-12 col-xs-12 q-gutter-sm text-center"
        >
          <div class="q-pa-md">
            <p class="text-h6 text-left text-primary">
              {{ this.selectedTitle }}
            </p>
            <q-video :ratio="16 / 9" :src="this.selectedVideo" />
          </div>
        </div>
        <div
          v-if="this.selectedPublication"
          class="col-md-8 col-sm-12 col-xs-12 q-gutter-sm"
        >
          <div class="">
            <div>
              <p class="text-h6 text-left text-primary">
                {{ this.selectedPublication.label }}
              </p>
              <q-item
                v-for="(file, key) in this.selectedPublication.files"
                :key="key"
                style="border-bottom: 1px solid lightgrey"
              >
                <q-item-section thumbnail>
                  <img
                    :src="file.thumbnail"
                    class="rounded_corners"
                    style="width: 140px; height: 100px; object-fit: cover"
                  />
                </q-item-section>
                <q-item-section class="text-left">
                  <q-item-label class="text-subtitle2">
                    {{ file.label }}
                  </q-item-label>
                  <q-item-label class="text-caption">
                    {{ file.subheader }}
                  </q-item-label>
                  <div class="row q-mt-md">
                    <q-btn
                      v-if="file.PDF"
                      color="blue-grey-9"
                      icon="fa-solid fa-file-pdf"
                      flat
                      label="PDF"
                      type="a"
                      @click="
                        this.$store.commit('updateShowHelp', {
                          url: file.PDF,
                          filename: file.label,
                        })
                      "
                      size="sm"
                    ></q-btn>
                    <q-btn
                      v-if="file.Español"
                      color="blue-grey-9"
                      icon="fa-solid fa-file-pdf"
                      flat
                      label="Español"
                      size="sm"
                      @click="
                        this.$store.commit('updateShowHelp', {
                          url: file.Español,
                          filename: file.label,
                        })
                      "
                    ></q-btn>
                    <q-btn
                      v-if="file.powerpoint"
                      color="secondary"
                      icon="download"
                      flat
                      label="Power Point"
                      type="a"
                      :href="file.powerpoint"
                      target="_blank"
                      size="sm"
                    ></q-btn>
                  </div>
                </q-item-section>
              </q-item>
            </div>
          </div>
        </div>
      </div>
    </div>
  </q-scroll-area>
</template>

<script>
export default {
  components: {},
  name: 'Resources',
  computed: {},

  data() {
    return {
      expanded: ['Videos', 'Training Videos'],
      selectedPublication: '',
      selectedVideo: 'https://www.youtube.com/embed/y-o4kGhYTfw',
      selectedTitle: 'Introduction',
      resourcesTree: [
        {
          label: 'Videos',
          selectable: false,
          expandable: true,
          icon: 'play_circle',
          children: [
            {
              label: 'Training Videos',
              selectable: false,
              expandable: true,
              children: [
                {
                  label: 'Introduction',
                  url: 'https://www.youtube.com/embed/y-o4kGhYTfw',
                  selectable: true,
                },
                {
                  label: 'How to calculate DBH',
                  url: 'https://www.youtube.com/embed/yoMkfPpS_b0',
                  selectable: true,
                },
                {
                  label: 'Fine Twig Dieback',
                  url: 'https://www.youtube.com/embed/xO1NqQcG4wM',
                  selectable: true,
                },
                {
                  label: 'Leaf Discoloration',
                  url: 'https://www.youtube.com/embed/lNi6-kkjHgY',
                  selectable: true,
                },
                {
                  label: 'Leaf Defoliation',
                  url: 'https://www.youtube.com/embed/SZnqGwXplxU',
                  selectable: true,
                },
                {
                  label: 'Crown Vigor',
                  url: 'https://www.youtube.com/embed/V90Q1jAOIOI',
                  selectable: true,
                },
                {
                  label: 'Crown Transparency',
                  url: 'https://www.youtube.com/embed/Fi88Uy8CPiw',
                  selectable: true,
                },
                {
                  label: 'Crown Light Exposure',
                  url: 'https://www.youtube.com/embed/zyIyBx11Bh8',
                  selectable: true,
                },
              ],
            },
            {
              label: 'Tree Care Videos',
              selectable: false,
              children: [
                {
                  label: 'Planting',
                  url: 'https://www.youtube.com/embed/wvMqCcm3ZJ4',
                  selectable: true,
                },
                {
                  label: 'Stewardship',
                  url: 'https://www.youtube.com/embed/3IEoe0X3w08',
                  selectable: true,
                },
                {
                  label: 'Pruning',
                  url: 'https://www.youtube.com/embed/M1wVL5InBxM',
                  selectable: true,
                },
              ],
            },
            {
              label: 'Pest ID Videos',
              selectable: false,
              children: [
                {
                  label: 'Asian Longhorned Beetle',
                  url: 'https://www.youtube.com/embed/yh6hyMNN0W0',
                  selectable: true,
                },
                {
                  label: 'Emerald Ash Borer',
                  url: 'https://www.youtube.com/embed/KxDDrCB4n9Q',
                  selectable: true,
                },
                {
                  label: 'Goldspotted Oak Borer',
                  url: 'https://www.youtube.com/embed/t201pD320w0',
                  selectable: true,
                },
                {
                  label: 'Gypsy Moth',
                  url: 'https://www.youtube.com/embed/qPWnLd8-XOA',
                  selectable: true,
                },
                {
                  label: 'Hemlock Woolly Adelgid',
                  url: 'https://www.youtube.com/embed/BDlCw0Qnpvg',
                  selectable: true,
                },
                {
                  label: 'Polyphagous Shot Hole Borer',
                  url: 'https://www.youtube.com/embed/BDlCw0Qnpvg',
                  selectable: true,
                },
                {
                  label: 'Thousand Cankers Disease',
                  url: 'https://www.youtube.com/embed/Ul7XGvCezXw',
                  selectable: true,
                },
                {
                  label: 'Winter Moth',
                  url: 'https://www.youtube.com/embed/kYgA7Lj3uVE',
                  selectable: true,
                },
              ],
            },
          ],
        },
        {
          label: 'Guides',
          selectable: false,
          icon: 'article',
          children: [
            {
              label: 'Pest Symptom Guides',
              selectable: true,
              pdf: '/resources/publications/guides/Pest Symptom Guides.pdf',
              filename: 'Pest Symptom Guides',
            },
            {
              label: 'DBH Measurement Guide',
              selectable: true,
              pdf: '/resources/publications/guides/DBHMeasurementGuide.pdf',
              filename: 'DBH Measurement Guide',
            },
            {
              label: 'Tree Health Metrics Field Guide',
              selectable: true,
              pdf: '/resources/publications/guides/Tree Health Field Guide current.pdf',
              filename: 'Tree Health Metrics Field Guide',
            },
            {
              label: 'HTHC Fact Sheet',
              selectable: true,
              pdf: '/resources/publications/guides/HTHC Fact Sheet 2023.pdf',
              filename: 'HTHC Fact Sheet',
            },
            {
              label: 'Crown Health Guide',
              selectable: true,
              pdf: '/resources/publications/guides/CrownHealthOnePageGuides.pdf',
              filename: 'CrownHealthOnePageGuides.pdf',
            },
          ],
        },
        {
          label: 'Publications',
          selectable: false,
          icon: 'article',
          children: [
            {
              label: 'Best Management Practices',
              files: [
                {
                  label: 'Right Tree Right Place',
                  subheader:
                    'Follow these guidelines to plant the best tree for your location',
                  html: '/resources/publications/RightTreeRightPlace.html',
                  thumbnail: '/resources/publications/right-tree.gif',
                  PDF: '/resources/downloads/BMP_RightTreeRightPlace.pdf',
                  Español:
                    '/resources/downloads/BMP_ArbolCorrectoLugarCorrecto.pdf',
                },
                {
                  label: 'Tools of the Trade',
                  subheader:
                    'Choose the right tools to plant and care for trees safely and effectively',
                  html: '/resources/publications/ToolsOfTheTrade.html',
                  thumbnail: '/resources/publications/tools.png',
                  PDF: '/resources/downloads/BMP_ToolsoftheTrade.pdf',
                  Español:
                    '/resources/downloads/BMP_LosMaterialesdelaPractica.pdf',
                },
                {
                  label: 'Tree Monitoring Schedule',
                  subheader:
                    "Keep track of your tree's checkups to keep it healthy",
                  html: '/resources/publications/Schedule.html',
                  thumbnail: '/resources/publications/schedule.gif',
                  PDF: '/resources/downloads/BMP_TreeMonitoringSchedule.pdf',
                  Español:
                    '/resources/downloads/BMP_HorariodeMonitorizaciondeArboles.pdf',
                },
                {
                  label: 'Watering Your Tree',
                  subheader:
                    'Learn how and when to water your tree to help it grow and thrive',
                  html: '/resources/publications/WateringYourTree.html',
                  thumbnail: '/resources/publications/watering.png',
                  PDF: '/resources/downloads/BMP_WateringYourTree.pdf',
                  Español: '/resources/downloads/BMP_RegandoleAguaaTuArbol.pdf',
                },
              ],
            },
            {
              label: 'Tree Planting and Stewardship',
              files: [
                {
                  label: 'Tree Owners Manual',
                  subheader:
                    'Follow these guidelines to plant the best tree for your location',
                  thumbnail: '/resources/publications/TreeOwners.png',
                  PDF: '/resources/downloads/TreeOwnersManualUSFS.pdf',
                },
                {
                  label: 'USDA Sustaining Americas Urban Trees + Forests',
                  subheader:
                    'Choose the right tools to plant and care for trees safely and effectively',
                  thumbnail: '/resources/publications/Sustaining.png',
                  PDF: '/resources/downloads/UrbanTreesandForests.pdf',
                },
              ],
            },
            {
              label: 'Pest and Disease Identification',
              files: [
                {
                  label: 'Asian Long Horned Beetle',
                  subheader: 'Anoplophora glabripennis',
                  html: '/resources/publications/AsianLongHornedBeetle.html',
                  thumbnail: '/resources/publications/AsianBeatle.jpg',
                  powerpoint:
                    '/resources/downloads/TPPD_AsianLongHornedBeetle.pptx',
                },
                {
                  label: 'Balsam Woolly Adelgid',
                  subheader: 'Adelges piceae',
                  html: '/resources/publications/BalsamWoollyAdelgid.html',
                  thumbnail: '/resources/publications/BalsamWoolly.jpg',
                  powerpoint:
                    '/resources/downloads/TPPD_Balsam Woolly Adelgid.pptx',
                },
                {
                  label: 'Citrus Greening',
                  subheader: 'Candidatus Liberibacter asiaticus',
                  html: '/resources/publications/CitrusGreening.html',
                  thumbnail: '/resources/publications/CitrusGreening.jpg',
                  powerpoint: '/resources/downloads/TPPD_Citrus Greening.pptx',
                },
                {
                  label: 'Emerald Ash Borer',
                  subheader: 'Agrilus planipennis',
                  html: '/resources/publications/EmeraldAshBorer.html',
                  thumbnail: '/resources/publications/EmeraldAsh.jpg',
                  powerpoint:
                    '/resources/downloads/TPPD_Emerald Ash Borer.pptx',
                },
                {
                  label: 'Gold Spotted Oak Borer',
                  subheader: 'Agrilus coxalis',
                  html: '/resources/publications/GoldSpottedOakBorer.html',
                  thumbnail: '/resources/publications/GoldSpotted.jpg',
                  powerpoint:
                    '/resources/downloads/TPPD_Gold Spotted Oak Borer.pptx',
                },
                {
                  label: 'Gypsy Moth',
                  subheader: 'Lymantria dispar dispar',
                  html: '/resources/publications/GypsyMoth.html',
                  thumbnail: '/resources/publications/GypsyMoth.jpg',
                  powerpoint: '/resources/downloads/TPPD_Gypsy Moth.pptx',
                },
                {
                  label: 'Hemlock Woolly Adelgid',
                  subheader: 'Adelges tsugae',
                  html: '/resources/publications/HemlockWoollyAdelgid.html',
                  thumbnail: '/resources/publications/HemlockWoolly.jpg',
                  powerpoint:
                    '/resources/downloads/TPPD_Hemlock Woolly Adelgid.pptx',
                },
                {
                  label: 'Oak Wilt',
                  subheader: 'Ceratocystis fagacearum',
                  html: '/resources/publications/OakWilt.html',
                  thumbnail: '/resources/publications/OakWilt.jpg',
                  powerpoint: '/resources/downloads/TPPD_Oak Wilt.pptx',
                },
                {
                  label: 'Polyphagous Shot Hole Borer',
                  subheader: 'Euwallacea sp.',
                  html: '/resources/publications/PolyphagousShotHoleBorer.html',
                  thumbnail: '/resources/publications/Polyphagous.jpg',
                  powerpoint:
                    '/resources/downloads/TPPD_Polyphagous Shot Hole Borer.pptx',
                },
                {
                  label: 'Sirex Woodwasp',
                  subheader: 'Sirex noctilio',
                  html: '/resources/publications/SirexWoodwasp.html',
                  thumbnail: '/resources/publications/Woodwasp.jpg',
                  powerpoint: '/resources/downloads/TPPD_Sirex Woodwasp.pptx',
                },

                {
                  label: 'Sudden Oak Death',
                  subheader: 'Phytophthora ramorum',
                  html: '/resources/publications/SuddenOakDeath.html',
                  thumbnail: '/resources/publications/SuddenOak.jpg',
                  powerpoint: '/resources/downloads/TPPD_Sudden Oak Death.pptx',
                },
                {
                  label: 'Thousand Canker Disease',
                  subheader: 'Pityophthorus juglandis',
                  html: '/resources/publications/ThousandCankerDisease.html',
                  thumbnail: '/resources/publications/Thousand.jpg',
                  powerpoint:
                    '/resources/downloads/TPPD_Thousand Canker Disease.pptx',
                },
                {
                  label: 'Viburnum Leaf Beetle',
                  subheader: 'Pyrrhalta viburni',
                  html: '/resources/publications/ViburnumLeafBeetle.html',
                  thumbnail: '/resources/publications/Viburnum.jpg',
                  powerpoint:
                    '/resources/downloads/TPPD_Viburnum Leaf Beetle.pptx',
                },
                {
                  label: 'Winter Moth',
                  subheader: 'Operophtera brumata',
                  html: '/resources/publications/WinterMoth.html',
                  thumbnail: '/resources/publications/WinterMoth.jpg',
                  powerpoint: '/resources/downloads/TPPD_Winter Moth.pptx',
                },
              ],
            },
            {
              label: 'Wallet Cards',
              files: [
                {
                  label: 'Eastern States Forest Pests Pocket Guide',
                  subheader:
                    "Refer to this wallet-sized guide if you think you've spotted one of these 6 major tree pests/diseases affecting trees in the Eastern US",
                  html: '/resources/publications/EasternUS.html',
                  thumbnail: '/resources/publications/easternus.png',
                },
                {
                  label: 'Western States Forest Pests Guide',
                  subheader:
                    "Refer to this wallet-sized guide if you think you've spotted one of these 6 major tree pests/diseases affecting trees in the Western US",
                  html: '/resources/publications/WesternUS.html',
                  thumbnail: '/resources/publications/westernus.png',
                },
              ],
            },
          ],
        },
      ],
    };
  },
  methods: {
    openLink() {
      window.open(
        'https://www.treesaregood.org/findanarborist/findanarborist',
        '_blank'
      );
    },
    clearAll() {
      this.selectedPublication = '';
      this.selectedVideo = '';
      this.selectedTitle = '';
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.q-tree__node {
  padding: 0 0 0 0 !important;
}
</style>
