<template>
  <div>
    <q-btn
      @click="logoutUser"
      size="18px"
      color="primary"
      class="q-mb-xs q-mt-md"
      >Logout</q-btn
    >
  </div>
</template>

<script>
export default {
  computed: {
    selectedView: {
      get() {
        return this.$store.state.app.selectedView;
      },
      set(value) {
        this.$store.commit('updateSelectedView', value);
      },
    },
  },
  methods: {
    logoutUser() {
      this.selectedView = 'login';
      console.log('logout user');
      this.$store.dispatch('logoutUser');
    },
  },
};
</script>

<style></style>
