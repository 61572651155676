<template>
  <!-- SETTINGS -->
  <TheLogoutButton />

  <q-btn
    flat
    href="https://forms.gle/38XfZzWnw96QpZddA"
    target="_blank"
    label="Submit a bug report"
    color="primary"
    class="q-mt-lg q-mb-md"
  ></q-btn
  ><br />

  <q-btn
    flat
    href="https://forms.gle/F6sGeLwLbM31EMPt8"
    target="_blank"
    label="Submit feedback"
    color="primary"
  ></q-btn>
</template>

<script>
import TheLogoutButton from './authentication/TheLogoutButton.vue';
export default {
  name: 'Settings',
  components: {
    TheLogoutButton,
  },
  methods: {},
};
</script>

<style scoped></style>
