const state = () => ({
  qaqcVisits: '',
  qaqcByProject: '',
  qaqcCompare: '',
  qaqcTreesGeoJson: { features: [] },
  exitQAQC: false,
  selectedQAQCTree: '',
  selectedQAQCVisit: '',
  selectedHealthVisit: '',
  editHealthVisit: false,
  percentComplete: { total_visits: 2, visits_complete: 0 },
});
const getters = {};
const mutations = {
  updateQAQCVisits(state, arr) {
    state.qaqcVisits = arr;
  },
  updateQAQCByProject(state, arr) {
    state.qaqcByProject = '';
    state.qaqcByProject = arr;
  },
  updateQAQCTreesGeoJson(state, arr) {
    state.qaqcTreesGeoJson = arr;
  },
  updateQAQCCompare(state, arr) {
    state.qaqcCompare = arr;
  },
  updateExitQAQC(state) {
    state.exitQAQC = !state.exitQAQC;
  },
  updateSelectedQAQCTree(state, obj) {
    state.selectedQAQCTree = obj;
  },
  updateSelectedQAQCVisit(state, obj) {
    state.selectedQAQCVisit = obj;
  },
  updatePercentComplete(state, obj) {
    state.percentComplete = obj;
  },
  updateSelectedHealthVisit(state, obj) {
    state.selectedHealthVisit = obj;
  },
  updateEditHealthVisit(state, bool) {
    state.editHealthVisit = bool;
  },
};

const actions = {
  async getQAQCByProject(context, val) {
    let projectId = val;
    try {
      const response = await context.dispatch('protectedApiRequest', {
        route: `/qaqc?projectId=${projectId}`,
        type: 'GET',
      });
      if (response.status === 200) {
        try {
          let arr = response.data.data;
          await context.commit('updateQAQCByProject', arr);
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getQAQCVisits(context, obj) {
    let qaqcId = obj.qaqcId;
    let projectId = obj.projectId;
    try {
      const response = await context.dispatch('protectedApiRequest', {
        route: `/qaqc/visits?qaqcId=${qaqcId}&projectId=${projectId}`,
        type: 'GET',
      });
      if (response.status === 200) {
        try {
          let arr = response.data.data;
          await context.commit('updateQAQCVisits', arr);
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async createQAQCVisits(context, obj) {
    let projectId = obj.projectId;
    let startDate = obj.startDate;
    let endDate = obj.endDate;
    try {
      const response = await context.dispatch('protectedApiRequest', {
        route: '/qaqc/visits',
        type: 'POST',
        body: {
          projectId: projectId,
          startDate: startDate,
          endDate: endDate,
        },
      });
      if (response.status === 200) {
        try {
          let arr = response.data.data;
          await context.dispatch('getQAQCByProject', projectId);
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getQAQCTrees(context, obj) {
    return new Promise(async (resolve, reject) => {
      const qaqcId = obj.qaqcId;
      const projectId = obj.projectId;
      const response = await context.dispatch('protectedApiRequest', {
        route: `qaqc/trees?qaqcId=${qaqcId}&projectId=${projectId}`,
        type: 'GET',
      });
      if (response.status === 200) {
        await context.dispatch('convertQAQCToGeojson', response.data.data);
        resolve();
      } else {
        reject();
      }
    });
  },
  async convertQAQCToGeojson(context, geoJson) {
    let geoJsonReformat = {
      type: 'FeatureCollection',
      features: [],
    };
    let total_visits = geoJson.length;
    let visits_complete = 0;
    geoJson.forEach((item) => {
      let geoJson = JSON.parse(item.point_geojson);
      geoJson.properties.uniqueId = item.unique_id;
      geoJson.properties.qaqcId = item.qaqc_id;
      geoJson.properties.projectId = item.project_id;
      if (geoJson.properties.symbology == 1) {
        visits_complete = visits_complete + 1;
      }
      geoJsonReformat.features.push(geoJson);
    });
    await context.commit('updatePercentComplete', {
      total_visits: total_visits,
      visits_complete: visits_complete,
    });

    await context.commit('updateQAQCTreesGeoJson', geoJsonReformat);
  },
  async getQAQCCompare(context, obj) {},
  async getQAQCTreeById(context, arr) {
    try {
      let treeId = arr[0];
      let projectId = arr[1];
      const response = await context.dispatch('protectedApiRequest', {
        route: `tree?treeId=${treeId}&projectId=${projectId}`,
        type: 'GET',
      });
      if (response.status === 200) {
        try {
          const treeData = response.data.data[0];

          const photos = await context.dispatch(
            'populateTreePhotoDownloadUrls',
            treeData
          );
          treeData.photosUrlArray = photos;

          await context.commit('updateSelectedQAQCTree', treeData);

          // call procedure to get activies from revisit table
          context.commit('updateLeftDrawer', {
            open: true,
            content: 'qaqc',
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async editQAQCHealthCheck(context, obj) {
    const uniqueId = obj.uniqueId;
    const qaqcId = obj.qaqcId;
    const projectId = obj.projectId;
    const inDbh = obj.inDbh;
    const inTreeheight = obj.inTreeheight;
    const inNotes = obj.inNotes;
    const fineTwigDieback = obj.fineTwigDieback;
    const leafDefoliation = obj.leafDefoliation;
    const leafDiscoloration = obj.leafDiscoloration;
    const crownVigor = obj.crownVigor;
    const crownLightExposure = obj.crownLightExposure;
    const crownDensityTrans = obj.crownDensityTrans;
    context.commit('updateSavingEdit', 0);
    const response = await context.dispatch('protectedApiRequest', {
      route: '/qaqc/visits',
      type: 'PUT',
      body: {
        uniqueId,
        qaqcId,
        projectId,
        inDbh,
        inTreeheight,
        inNotes,
        fineTwigDieback,
        leafDefoliation,
        leafDiscoloration,
        crownVigor,
        crownLightExposure,
        crownDensityTrans,
      },
    });
    if (response.status === 200) {
      context.commit('updateSavingEdit', 1);
      try {
        await context.dispatch('getQAQCTrees', {
          qaqcId: qaqcId,
          projectId: projectId,
        });

        context.commit('updatePercentComplete', response.data[0]);

        await context.commit('updateLeftDrawer', {
          open: false,
          content: '',
        });
      } catch (error) {
        console.log(error);
      }
      return true;
    } else {
      context.commit('updateSavingEdit', 2);
    }
  },
};

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
};
