<template>
  <!--this is the dialog message that confirms database transactions to the user-->
  <q-dialog
    v-model="savingEdit"
    seamless
    allow-focus-outside
    no-focus
    position="top"
  >
    <q-card class="bg-grey-1 text-h6 q-pa-sm text-center">
      <div style="color: var(--q-primary) !important">{{ savingMessage }}</div>
    </q-card>
  </q-dialog>

  <!--this is the pdf viewer. -->
  <div class="fullscreen" v-show="show">
    <div class="bg-white">
      <q-btn
        size="md"
        color="white"
        text-color="primary"
        label=""
        class="q-mt-xs"
        @click="this.closePDF()"
        v-show="show"
        style="width: 150px"
        >CLOSE</q-btn
      >
    </div>
    <div id="adobe" style="height: calc(100vh - 41px)"></div>
  </div>

  <q-layout view="lHh lpR lFf">
    <q-header class="bg-white text-primary">
      <q-toolbar style="border-bottom: 0.5px solid #49a942">
        <q-toolbar-title> Healthy Trees, Healthy Cities </q-toolbar-title>

        <q-btn
          icon="sym_s_account_circle"
          color="primary"
          flat
          round
          padding="sm"
          @click="updateSelectedView('settings')"
        ></q-btn>
      </q-toolbar>
    </q-header>

    <q-drawer
      v-model="$store.state.app.leftDrawer.open"
      side="left"
      bordered
      overlay
      :width="this.width"
    >
      <!-- drawer content -- Forms and Dialogs show up here-->
      <DialogFullImage
        v-if="$store.state.app.leftDrawer.content == 'image'"
      ></DialogFullImage>

      <FormAddTree
        v-if="$store.state.app.leftDrawer.content == 'addtree'"
      ></FormAddTree>

      <DialogTreeHome
        v-if="$store.state.app.leftDrawer.content == 'treehome'"
      ></DialogTreeHome>

      <QAQCTreeHome v-if="$store.state.app.leftDrawer.content == 'qaqc'">
      </QAQCTreeHome>

      <FormAddProject
        v-if="$store.state.app.leftDrawer.content == 'newproject'"
      >
      </FormAddProject>

      <dialog-approvals
        v-if="$store.state.app.leftDrawer.content == 'approvals'"
      ></dialog-approvals>
    </q-drawer>
    <!--these are the tabs at the bottom (and login button)-->
    <q-page-container class="" style="padding-top: 1px">
      <keep-alive>
        <Trees v-if="this.selectedView == 'trees'"></Trees
      ></keep-alive>
      <keep-alive> <Map v-if="this.selectedView == 'map'"></Map></keep-alive>
      <settings v-if="this.selectedView == 'settings'"></settings>
      <TheLoginForm v-if="this.selectedView == 'login'"></TheLoginForm>
      <Resources v-if="this.selectedView == 'resources'"></Resources>
      <Intro v-if="this.selectedView == 'intro'"></Intro>
    </q-page-container>

    <q-footer elevated class="bg-grey-8 text-white">
      <q-footer class="bg-white">
        <q-btn-group
          spread
          class="text-blue q-mb-md no-shadow"
          style="border-top: 1px solid #49a942"
        >
          <q-btn
            :class="
              this.selectedView == 'trees' ? 'text-primary' : 'text-grey-6'
            "
            stack
            flat
            size=""
            color=""
            label="Trees"
            icon="sym_s_folder_shared"
            :ripple="noRipple"
            @click="updateSelectedView('trees')"
            no-caps
          />

          <q-btn
            :class="this.selectedView == 'map' ? 'text-primary' : 'text-grey-6'"
            stack
            flat
            size=""
            color=""
            label="Map"
            icon="sym_s_nature"
            :ripple="noRipple"
            @click="updateSelectedView('map')"
            no-caps
          />

          <q-btn
            :class="
              this.selectedView == 'resources' ? 'text-primary' : 'text-grey-6'
            "
            stack
            flat
            size=""
            color=""
            label="Resources"
            icon="sym_s_video_library"
            :ripple="noRipple"
            @click="updateSelectedView('resources')"
            no-caps
          />
        </q-btn-group>
      </q-footer>
    </q-footer>
  </q-layout>
</template>

<script>
import Trees from './components/Trees.vue';
import settings from './components/settings.vue';
import Resources from './components/Resources.vue';
import TheLoginForm from './components/authentication/TheLoginForm.vue';
import Map from './components/Map.vue';
import DialogTreeHome from './components/DialogTreeHome.vue';
import QAQCTreeHome from './components/QAQCTreeHome.vue';
import FormAddTree from './components/FormAddTree.vue';
import FormAddProject from './components/FormAddProject.vue';
import DialogFullImage from './components/DialogFullImage.vue';
import Intro from './components/Intro.vue';
import DialogApprovals from './components/DialogApprovals.vue';

import { mapMutations } from 'vuex';

let adobeDCView = '';
export default {
  name: 'App',
  data() {
    return {
      noRipple: false,
      width: 0,
      show: false,
    };
  },
  computed: {
    selectedView() {
      return this.$store.state.app.selectedView;
    },
    savingEdit: {
      get() {
        return this.$store.state.savingEdit;
      },
      set(value) {
        this.$store.commit('updateSavingEdit', value);
      },
    },
    savingMessage() {
      return this.$store.state.savingMessage;
    },
    showHelp() {
      return this.$store.state.app.showHelp;
    },
  },
  watch: {
    showHelp() {
      this.showPDF();
    },
  },
  methods: {
    ...mapMutations(['updateSelectedView']),

    closePDF() {
      this.show = false;
      document.getElementById('adobe').hidden = true;
    },

    showPDF() {
      document.getElementById('adobe').hidden = false;
      this.show = true;

      let clientId = '';
      console.log(window.location.hostname);
      if (window.location.hostname === 'localhost') {
        clientId = 'fce86228a15a4f1ab7083f7b4fb9bbcd';
      }
      if (window.location.hostname === 'dev-hthc-mobile.netlify.app') {
        clientId = 'e65ece398c974b8ba63633c00fece23b';
      }
      if (window.location.hostname === 'hthc-mobile.netlify.app') {
        clientId = 'f53420e50e7943429deb2d9cabff6fdb';
      }
      adobeDCView = new AdobeDC.View({
        clientId: clientId,
        divId: 'adobe',
      });

      adobeDCView.previewFile(
        {
          content: {
            location: {
              url: this.$store.state.app.showHelp.url,
            },
          },
          metaData: { fileName: this.$store.state.app.showHelp.filename },
        },
        { ZOOM_LEVEL: 100 }
      );
    },
  },
  async mounted() {
    //check to see if user has seen the intro before....
    //if they did then route them to login at load above
    //get screen width
    // const userSession = await this.$store.dispatch('getCurrentUserSession');
    this.$store.dispatch('loginAtAppLoad');

    // if (userSession) {
    //   this.$store.dispatch('loginAtAppLoad');
    // }
    this.width = parseInt(this.$q.screen.width);
  },
  components: {
    Trees,
    Map,
    settings,
    Resources,
    TheLoginForm,
    DialogTreeHome,
    DialogFullImage,
    FormAddTree,
    Intro,
    FormAddProject,
    DialogApprovals,
    QAQCTreeHome,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 50px;
}
</style>
