import AWS from 'aws-sdk';
// import AWS from 'aws-sdk/client-s3'; // this is the newest version but wont install

const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
  apiVersion: '2016-04-18',
  region: 'us-east-2',
});

// ES AWS Modules
import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUserAttribute,
  CognitoUser,
} from 'amazon-cognito-identity-js';

// cognito setup SDK setup
// const clientId = '13v0kth3gp86aqpeue9f8ptcq9';
const clientId = '7vbu47fslnv5fvempteq6h5bqu';
// const userPoolId = 'us-east-2_NLwmH7WaZ';
const userPoolId = 'us-east-2_PtK3mbOi9';
const poolData = {
  UserPoolId: userPoolId, // Your user pool id here
  ClientId: clientId, // Your client id here
};

const userPool = new CognitoUserPool(poolData);

const state = () => ({
  authMode: 'login',
  loginError: '',
  signupError: '',
  userInfo: '',
});

const getters = {};

const mutations = {};

const actions = {
  async createAccount(context, data) {
    var params = {
      ClientId: clientId /* required */,
      Password: data.password /* required */,
      Username: data.email /* required */,
      UserAttributes: [
        {
          Name: 'given_name',
          Value: data.givenName,
        },
        {
          Name: 'family_name',
          Value: data.familyName,
        },
        {
          Name: 'custom:hthc_id',
          Value: 'null',
        },
        {
          Name: 'custom:hthc_org',
          Value: data.organization,
        },
        // include other standard attributes as needed
      ],
    };
    cognitoidentityserviceprovider.signUp(params, function (err, data) {
      if (err) {
        console.log(err, err.stack);
        if ((err.message = 'An account with the given email already exists.')) {
          context.state.signupError = 'accountAlreadyExists';
        } // an error occurred // an error occurred
      } else {
        console.log(data); // successful response}
        // redirect user to verify account with code page
        // set user email verified to false
        // make POST request to users table and add new user here.
        // add users name, picture, nickname,
        // add field, last logon, to find out stats on daily active users

        context.state.authMode = 'verify';
      }
    });
  },
  confirmSignup(context, data) {
    var params = {
      ClientId: clientId /* required */,
      ConfirmationCode: data.verifyCode /* required */,
      Username: data.email /* required */,
    };
    cognitoidentityserviceprovider.confirmSignUp(
      params,
      async function (err, data) {
        if (err) {
          console.log(err, err.stack);
        } else {
          console.log(data);
          // set user email verified to true

          context.state.authMode = 'login';
        } // successful response
      }
    );
  },
  resendConfirmationCode(context, email) {
    var params = {
      ClientId: clientId /* required */,
      Username: email /* required */,
    };
    cognitoidentityserviceprovider.resendConfirmationCode(
      params,
      function (err, data) {
        if (err) {
          console.log(err, err.stack);
        } else {
          context.state.authMode = 'verify';
        }
      }
    );
  },
  async login(context, data) {
    var authenticationData = {
      Username: data.email,
      Password: data.password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const cognitoUser = new CognitoUser({
      Username: data.email,
      Pool: userPool,
    });
    // let context = this;
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async function (result) {
        // test to see if hthc_id != null
        // if null
        // this is only called once when a user logs in for the first time.
        // subsequent calls this is called but nothing happens and is ignored
        // this function should return a user_id
        // the route would be a POST and expect Name, Last, Email, and Org
        // set cognito hthc_id with user_id thats returned
        // refresh user session/cognito token.
        if (result.idToken.payload['custom:hthc_id'] == 'null') {
          const userId = await context.dispatch('protectedApiRequest', {
            route: `user/validate`,
            type: 'POST',
            body: {
              email: result.idToken.payload.email,
              firstName: result.idToken.payload.given_name,
              lastName: result.idToken.payload.family_name,
              org: result.idToken.payload['custom:hthc_org'],
            },
          });

          // update user params
          const params = {
            AccessToken: result.accessToken.jwtToken,
            UserAttributes: [
              {
                Name: 'custom:hthc_id',
                Value: `${userId.data.data[0][0].user_id}`,
              },
            ],
          };
          cognitoidentityserviceprovider.updateUserAttributes(
            params,
            (error, response) => {
              console.log(response);
            }
          );
          await context.dispatch('logoutUser');
          await context.dispatch('login', {
            email: authenticationData.Username,
            password: authenticationData.Password,
          });

          // set app info after user is logged in
          await context.dispatch('loginAtAppLoad');
          return true;
        } else {
          await context.dispatch('loginAtAppLoad');
          return true;
        }

        // if hthc_id exsits
        // decrypt token on backend to get that ID and do everything with it
      },
      onFailure: function (err) {
        console.log(err);
        console.log(err.message);
        if (err.message == 'Incorrect username or password.') {
          context.state.loginError = 'incorectEmailPassword';
        }
        if (err.message == 'User is not confirmed.') {
          context.state.loginError = 'emailNotConfirmed';
        }
      },
      // newPasswordRequired: function (userAttributes, requiredAttributes) {
      //   // redirect to change-password page
      //   console.log('new password required');
      // },
    });
  },
  // logs user out of the application
  logoutUser(context) {
    const cognitoUser = userPool.getCurrentUser();
    // sign user out of application
    // this invalidates the AWS tokens.
    cognitoUser.signOut();
    context.state.userInfo = '';
    context.state.authMode = 'login';
    context.commit('updateSelectedView', 'login');
  },

  async loginAtAppLoad(context) {
    try {
      const userSession = await context.dispatch('getCurrentUserSession');
      if (!userSession) {
        context.state.authMode = 'login';
        context.commit('updateSelectedView', 'login');
        return;
      }
      // show the user page and some basic info
      // this will chnage depending o how we want to load the main site

      context.state.userInfo = userSession.idToken.payload;
      context.commit('updateSelectedView', 'map');
    } catch (error) {
      console.log('LOGIN at app load error.', error);
      context.state.authMode = 'login';
      context.commit('updateSelectedView', 'login');
    }
  },

  async getCurrentUserSession(context) {
    return new Promise(async (resolve, reject) => {
      let userSession;
      const cognitoUser = await userPool.getCurrentUser();
      // if no cognito user
      if (!cognitoUser) {
        console.log('Login Failed because no cognito user', cognitoUser);
        context.commit('updateSelectedView', 'login');
        context.state.authMode = 'login';
        resolve();
      }

      // get user session
      cognitoUser.getSession(async function (err, session) {
        // if error getting session
        if (err) {
          console.log('Login Failed because error getting session', err);
          context.commit('updateSelectedView', 'login');
          //maybe try to clear local stoarge and then redirect to login page
          resolve();
        }
        // if no session
        if (!session) {
          try {
            let session2 = await context.dispatch('refreshTokenSilent');
            resolve(session2);
          } catch (error) {
            console.log(
              'Login Failed because no session and token refresh failed',
              error
            );
            context.commit('updateSelectedView', 'login');
            resolve();
          }
        }

        // if session is in valid
        if (!session.isValid()) {
          try {
            let session2 = await context.dispatch('refreshTokenSilent');
            resolve(session2);
          } catch (error) {
            console.log(
              'Login Failed because session was invlaid and token refresh failed',
              error
            );
            context.commit('updateSelectedView', 'login');
            resolve();
          }
        }
        userSession = session;
      });
      resolve(userSession);
    });
  },
  async refreshTokenSilent(context) {
    return new Promise((resolve, reject) => {
      const cognitoUser = userPool.getCurrentUser();
      //
      if (!cognitoUser) {
        reject('There was a problem refeshing the token 1');
      }
      cognitoUser.getSession(function (err, session) {
        if (!session) {
          reject('There was a problem refeshing the token 2');
        }
        const refresh_token = session.getRefreshToken();
        cognitoUser.refreshSession(refresh_token, (err, session2) => {
          try {
            resolve(session2);
          } catch (error) {
            reject('There was a problem refeshing the token 3');
          }
        });
      });
    });
  },
  forgotPassword(context, email) {
    // setup cognitoUser first

    console.log(email);
    let cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    // call forgotPassword on cognitoUser
    cognitoUser.forgotPassword({
      onSuccess: function (result) {
        context.state.authMode = 'verifyForgot';
      },
      onFailure: function (err) {
        console.log(err);
      },
    });
  },
  async confirmForgotPassword(context, data) {
    let cognitoUser = new CognitoUser({
      Username: data.email,
      Pool: userPool,
    });

    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(data.verifyCode, data.newPassword, {
        onFailure(err) {
          reject(err);
        },
        onSuccess() {
          resolve();
          context.state.authMode = 'login';
        },
      });
    });
  },
};

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
};

// HTHC login flow ************
// User has an account with HTHC but not cognito (most existing users)
// 1. User goes through workflow to create cognito account
// 2. on success, send verify email
// 3. once verified, user should now login
// 4. once login success, now we have to check user table to see if they already exist
// 5. send cognito token (which contains email) to backend, test to see if email exists in DB
// 6. if it exists, they have an existing HTHC account
//     1. get the user_id from user table and populate the cognito instance with that ID
//     2. that allows the cognito token to now contain user_id from the existing user table
//     3. this preserves all of the relationships
//     4. so now when a user makes a request for data, we validate cognito token
//     5. but query db info with the user_id (also contained in the cognito token)
// 7. if user does not exist
//    1. create new row for user.
//    2. once that row is created, get user_id that is auto incremented from user table
//    3. take that user_id and send and store in cognito token
//    4. follow the rest of the flow starting at 6.2 above....
