const state = () => ({
  usersProjects: [], //all projects the user is associated with
  projectList: [], //
  treeProjectList: [], //if a tree-user has more than one project
  selectedProject: {}, //users selection if a tree-user has more than one project
  usersLocation: {},
  usersStartLocation: {},
  newProjectExtent: { polyText: null, treeCount: null, includeTrees: '1' },
  nearbyProjects: [],
  projectSearchResults: [],
  memberRequests: [],
  projectsTab: 'myprojects',
});

const getters = {};

const mutations = {
  updateProjectsTab(state, data) {
    state.projectsTab = data;
  },
  updateUsersProjects(state, data) {
    state.usersProjects = data;
    state.projectList = [];
    data.forEach((d) => {
      state.projectList.push({ label: d.project_name, value: d.project_id });
    });
  },
  updateUsersLocation(state, obj) {
    state.usersLocation = obj;
  },
  updateUsersStartLocation(state, arr) {
    state.usersStartLocation = arr;
  },
  updateSelectedProject(state, obj) {
    state.selectedProject = obj;
  },
  updateTreeProjectList(state, arr) {
    state.treeProjectList = arr;
  },
  updateNewProjectExtent(state, obj) {
    state.newProjectExtent = obj;
  },
  updateNearbyProjects(state, arr) {
    state.nearbyProjects = arr;
  },
  updateProjectSearchResults(state, arr) {
    state.projectSearchResults = arr;
  },
  updateMemberRequests(state, arr) {
    state.memberRequests = arr;
  },
};

const actions = {
  async getUserInfo(context) {
    const response = await context.dispatch('protectedApiRequest', {
      route: `test`,
      type: 'GET',
    });
    context.state.userInfo = response.data.data[0];
  },
  async getUsersProjects(context) {
    const response = await context.dispatch('protectedApiRequest', {
      route: `user/projects`,
      type: 'GET',
    });
    context.commit('updateUsersProjects', response.data.data);
  },
  async createNewProject(context, obj) {
    let inCity = obj.inCity;
    let projectName = obj.projectName;
    let inDescription = obj.inDescription;
    let inExtent = obj.inExtent;
    let inObjectives = obj.inObjectives;
    let healthCheck = obj.healthCheck;
    let treeCare = obj.treeCare;
    let pestDetection = obj.pestDetection;
    let inVisible = obj.inVisible;
    let polyText = obj.polyText;
    let includeTrees = obj.includeTrees;
    let acceptingUsers = obj.acceptingUsers;
    context.commit('updateSavingEdit', 0);
    const response = await context.dispatch('protectedApiRequest', {
      route: '/project',
      type: 'POST',
      body: {
        inCity,
        projectName,
        inDescription,
        inExtent,
        inObjectives,
        healthCheck,
        treeCare,
        pestDetection,
        inVisible,
        polyText,
        includeTrees,
        acceptingUsers,
      },
    });
    if (response.status === 200) {
      context.commit('updateSavingEdit', 1);
      try {
        console.log(response);
        await context.dispatch('getUsersProjects');
        await context.commit('updateLeftDrawer', {
          open: false,
          content: '',
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      context.commit('updateSavingEdit', 2);
      console.log(error);
    }
  },
  async getNearbyProjects(context, polyText) {
    try {
      const response = await context.dispatch('protectedApiRequest', {
        route: `/project/nearme?polyText=${polyText}`,
        type: 'GET',
      });
      if (response.status === 200) {
        try {
          let obj = response.data.data;
          await context.commit('updateNearbyProjects', obj);
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getProjectByName(context, searchterm) {
    try {
      const response = await context.dispatch('protectedApiRequest', {
        route: `/project/byname?searchText=${searchterm}`,
        type: 'GET',
      });
      if (response.status === 200) {
        try {
          let obj = response.data.data;
          await context.commit('updateProjectSearchResults', obj);
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async joinProject(context, obj) {
    try {
      context.commit('updateSavingEdit', 0);
      let projectId = obj.projectId;
      const response = await context.dispatch('protectedApiRequest', {
        route: '/project/requests',
        type: 'POST',
        body: { projectId },
      });
      if (response.status === 200) {
        context.commit('updateSavingEdit', 1);
        try {
          context.dispatch('getNearbyProjects', obj.polyText);
          if (obj.searchterm) {
            context.dispatch('getProjectByName', obj.searchterm);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        context.commit('updateSavingEdit', 2);
        console.log(error);
      }
    } catch (error) {
      context.commit('updateSavingEdit', 2);
      console.log(error);
    }
  },
  async getMemberRequests(context, val) {
    let projectId = val;
    try {
      const response = await context.dispatch('protectedApiRequest', {
        route: `/project/approvals?projectId=${projectId}`,
        type: 'GET',
      });
      if (response.status === 200) {
        try {
          let obj = response.data.data;
          await context.commit('updateMemberRequests', obj);
          await context.commit('updateLeftDrawer', {
            open: true,
            content: 'approvals',
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async approveMember(context, obj) {
    try {
      context.commit('updateSavingEdit', 0);
      let projectId = obj.projectId;
      let roleId = obj.roleId;
      let requestId = obj.requestId;

      const response = await context.dispatch('protectedApiRequest', {
        route: '/project/approvals',
        type: 'POST',
        body: { projectId, roleId, requestId },
      });
      if (response.status === 200) {
        context.commit('updateSavingEdit', 1);
        try {
          context.dispatch('getMemberRequests', obj.projectId);
        } catch (error) {
          console.log(error);
        }
      } else {
        context.commit('updateSavingEdit', 2);
        console.log(error);
      }
    } catch (error) {
      context.commit('updateSavingEdit', 2);
      console.log(error);
    }
  },
  async denyMember(context, obj) {
    try {
      context.commit('updateSavingEdit', 0);
      let projectId = obj.projectId;
      let requestId = obj.requestId;
      console.log(obj);
      const response = await context.dispatch('protectedApiRequest', {
        route: `/project/approvals?requestId=${requestId}&projectId=${projectId}`,
        type: 'DELETE',
      });
      if (response.status === 200) {
        context.commit('updateSavingEdit', 1);
        try {
          context.dispatch('getMemberRequests', obj.projectId);
        } catch (error) {
          console.log(error);
        }
      } else {
        context.commit('updateSavingEdit', 2);
        console.log(error);
      }
    } catch (error) {
      context.commit('updateSavingEdit', 2);
      console.log(error);
    }
  },
};

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
};
