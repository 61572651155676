<template>
  <div class="">
    <q-card-actions align="left">
      <q-btn
        flat
        icon="sym_s_arrow_back"
        label=""
        color="primary"
        @click="
          $store.commit('updateLeftDrawer', { open: false });
          this.$store.commit('updateEditTreeMode', false);
        "
      />
      <q-chip size="lg" color="white" class="text-primary">Add Tree</q-chip>
    </q-card-actions>
    <div class="q-ma-md">
      <q-form @submit="onSubmit" @reset="onReset" class="q-gutter-sm">
        <q-tabs
          v-model="tab"
          dense
          class="text-grey"
          active-color="primary"
          indicator-color="primary"
          align="justify"
          narrow-indicator
        >
          <q-tab name="existing" label="Existing" />
          <q-tab name="planting" label="Planting" />
        </q-tabs>

        <q-separator />
        <q-input
          color="secondary"
          outlined
          square
          v-model="inName"
          label="Tree Name"
          style="font-size: 16px"
          class="q-mt-lg"
          :rules="[(val) => !!val || 'Field is required']"
        />
        <q-select
          v-model="subId"
          :options="speciesOptions"
          @filter="speciesFilterFn"
          use-input
          input-debounce="0"
          label="Species"
          class="q-mb-sm"
          outlined
          square
          :rules="[
            (subId) =>
              !!subId ||
              'Required - Consider selecting Unknown plant or Unlisted plant',
          ]"
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey"> No results </q-item-section>
            </q-item>
          </template>
          <template v-if="subId" v-slot:append>
            <q-icon
              name="cancel"
              @click.stop.prevent="subId = null"
              class="cursor-pointer"
            />
          </template>
        </q-select>
        <q-select
          v-model="projectId"
          :options="projectOptions"
          @filter="projectFilterFn"
          use-input
          input-debounce="0"
          label="Project"
          class="q-mb-sm"
          outlined
          square
          :rules="[(projectId) => !!projectId || 'Field is required']"
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey"> No results </q-item-section>
            </q-item>
          </template>
          <template v-if="projectId" v-slot:append>
            <q-icon
              name="cancel"
              @click.stop.prevent="projectId = null"
              class="cursor-pointer"
            />
          </template>
        </q-select>
        <q-tab-panels v-model="tab" animated>
          <q-tab-panel name="existing" class="q-pa-none">
            <div class="text-left">
              <q-btn-toggle
                v-model="units"
                style="border: 1px solid green"
                class="q-mb-md"
                no-caps
                rounded
                unelevated
                toggle-color="primary"
                color="white"
                text-color="primary"
                :options="[
                  { label: 'IN', value: 'in' },
                  { label: 'CM', value: 'cm' },
                ]"
              >
              </q-btn-toggle>

              <q-btn
                round
                dense
                flat
                icon="sym_s_info"
                color="blue-grey-9"
                class="float-right q-mb-md"
                @click="
                  this.$store.commit('updateShowHelp', {
                    url: '/help/DBH_Flowcharts.pdf',
                    filename: 'DBH_Flowcharts.pdf',
                  })
                "
              />
            </div>

            <div class="row justify-center" v-if="units == 'in'">
              <div class="col-5">
                <q-input
                  color="secondary"
                  class="q-mb-sm"
                  outlined
                  square
                  v-model="inDbh"
                  @update:model-value="convertUnits('dbhin')"
                  label="DBH (in)"
                  style="font-size: 16px"
                ></q-input>
              </div>
              <div class="col-1">Or</div>
              <div class="col-5">
                <q-input
                  color="secondary"
                  class="q-mb-sm"
                  outlined
                  square
                  v-model="circumfrance"
                  label="Circumference (in)"
                  @update:model-value="convertUnits('circin')"
                  style="font-size: 16px"
                ></q-input>
              </div>
            </div>

            <div class="row justify-center" v-if="units == 'cm'">
              <div class="col-5">
                <q-input
                  color="secondary"
                  class="q-mb-sm"
                  outlined
                  square
                  v-model="inDBHCm"
                  @update:model-value="convertUnits('dbhcm')"
                  label="DBH (cm)"
                  style="font-size: 16px"
                ></q-input>
              </div>
              <div class="col-1">Or</div>
              <div class="col-5">
                <q-input
                  color="secondary"
                  class="q-mb-sm"
                  outlined
                  square
                  v-model="circCm"
                  label="Circumference (cm)"
                  @update:model-value="convertUnits('circcm')"
                  style="font-size: 16px"
                ></q-input>
              </div>
            </div>
          </q-tab-panel>

          <q-tab-panel name="planting" class="q-pa-none">
            <div class="q-gutter-sm q-mb-md text-left">
              <q-radio
                size="sm"
                v-model="rootConfig"
                val="containerized"
                label="Containerized"
              />
              <q-radio
                size="sm"
                v-model="rootConfig"
                val="bareroot"
                label="Bare Root"
              />
              <q-radio
                size="sm"
                v-model="rootConfig"
                val="bailedandburlap"
                label="Balled and Burlapped(B&B)"
              />
            </div>

            <q-input
              color="secondary"
              class="q-mb-lg"
              outlined
              square
              v-model="containerSize"
              label="Container Size (gallons)"
              style="font-size: 16px"
            />
            <q-input
              color="secondary"
              class="q-mb-md"
              outlined
              square
              v-model="caliperSize"
              label="Caliper Size (inches)"
              style="font-size: 16px"
            />
          </q-tab-panel>
        </q-tab-panels>

        <q-input
          style="font-size: 16px"
          autogrow
          label="Address"
          outlined
          square
          class="q-mb-lg"
          v-model="inAddress"
        />
        <q-input
          color="secondary"
          class="q-mb-sm q-mt-md q-mb-lg"
          outlined
          square
          v-model="inTreeheight"
          label="Tree Height (feet) - Optional"
          style="font-size: 16px"
        />
        <q-input
          style="font-size: 16px"
          autogrow
          label="Notes"
          outlined
          square
          v-model="inNotes"
        />
        <div>
          <q-btn label="Submit" type="submit" color="primary" />
          <q-btn
            label="Reset"
            type="reset"
            color="primary"
            flat
            class="q-ml-sm"
          />
        </div>
      </q-form>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
export default {
  name: 'FormAddTree',
  data() {
    return {
      tab: 'existing',
      units: 'in',
      speciesOptions: this.$store.state.treesStore.treeList,
      projectOptions: this.$store.state.userStore.projectList,
      inName: this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedTree.tree_name
        : null,
      subId: this.$store.state.treesStore.editTreeMode
        ? {
            label: this.$store.state.treesStore.selectedTree.scientific_name,
            value: this.$store.state.treesStore.selectedTree.sub_id,
          }
        : null,
      projectId: this.$store.state.treesStore.editTreeMode
        ? {
            label: this.$store.state.treesStore.selectedTree.project_name,
            value: this.$store.state.treesStore.selectedTree.project_id,
          }
        : null,
      circumfrance: '',
      inDBHCm: '',
      circCm: '',
      inDbh: this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedTree.dbh
        : null,
      inTreeheight: this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedTree.treeheight
        : null,
      inAddress: this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedTree.address
        : null,
      inNotes: this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedTree.notes
        : null,
      rootConfig: this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedTree.root_config
        : null,
      containerSize: this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedTree.container_size
        : null,
      caliperSize: this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedTree.caliper_size
        : null,
    };
  },
  computed: {
    inLatitude() {
      return this.$store.state.treesStore.latitude;
    },
    inLongitude() {
      return this.$store.state.treesStore.longitude;
    },
  },
  watch: {
    circCm() {
      console.log('here');
      this.calcDBHCm();
    },
    circumfrance() {
      console.log('ichanged');
      this.calcDBH();
    },
  },
  mounted() {
    this.$store.dispatch('getTreeList');
    this.$store.dispatch('getUsersProjects');
  },
  methods: {
    speciesFilterFn(val, update, abort) {
      Object.filter = (obj, predicate) =>
        Object.keys(obj)
          .filter((key) => predicate(obj[key]))
          .reduce((res, key) => ((res[key] = obj[key]), res), {});
      update(() => {
        const needle = val.toLowerCase();
        this.speciesOptions = this.$store.state.treesStore.treeList.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    projectFilterFn(val, update, abort) {
      Object.filter = (obj, predicate) =>
        Object.keys(obj)
          .filter((key) => predicate(obj[key]))
          .reduce((res, key) => ((res[key] = obj[key]), res), {});
      update(() => {
        const needle = val.toLowerCase();
        this.projectOptions = this.$store.state.userStore.projectList.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    onSubmit() {
      let obj = {
        projectId: this.projectId.value,
        treeId: this.$store.state.treesStore.selectedTree.tree_id,
        subId: this.subId.value,
        inName: this.inName,
        inLatitude: this.inLatitude,
        inLongitude: this.inLongitude,
        inNotes: this.inNotes,
        inAddress: this.inAddress,
        rootConfig: this.rootConfig,
        containerSize: this.containerSize,
        caliperSize: this.caliperSize,
        inDbh: this.inDbh,
        inTreeheight: this.inTreeheight,
      };
      console.log(obj);
      if (this.$store.state.treesStore.editTreeMode) {
        this.$store.dispatch('editTree', obj);
      } else {
        this.$store.dispatch('createNewTree', obj);
      }
    },
    onReset() {
      (this.tab = 'existing'),
        (this.inName = this.$store.state.treesStore.editTreeMode
          ? this.$store.state.treesStore.selectedTree.tree_name
          : null);
      this.subId = this.$store.state.treesStore.editTreeMode
        ? {
            label: this.$store.state.treesStore.selectedTree.scientific_name,
            value: this.$store.state.treesStore.selectedTree.sub_id,
          }
        : null;
      this.projectId = this.$store.state.treesStore.editTreeMode
        ? {
            label: this.$store.state.treesStore.selectedTree.project_name,
            value: this.$store.state.treesStore.selectedTree.project_id,
          }
        : null;
      this.inDbh = this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedTree.dbh
        : null;
      this.inTreeheight = this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedTree.treeheight
        : null;
      this.inAddress = this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedTree.address
        : null;
      this.inNotes = this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedTree.notes
        : null;
      this.rootConfig = this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedTree.root_config
        : null;
      this.containerSize = this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedTree.container_size
        : null;
      this.caliperSize = this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedTree.caliper_size
        : null;
    },
    convertUnits(input) {
      //convert units function will be triggered anytime the four input boxes are updated
      if (this.units == 'cm') {
        if (input == 'dbhcm') {
          if (this.inDBHCm.length > 0) {
            let conv = this.inDBHCm / 2.54;
            this.inDbh = Math.round(conv * 100) / 100;
          } else {
            this.inDbh = '';
            this.circCm = '';
            this.circumfrance = '';
          }
        } else if (input == 'circcm') {
          if (this.circCm.length > 0) {
            let conv = this.circCm / 2.54;
            this.circumfrance = Math.round(conv * 100) / 100;
          } else {
            this.circumfrance = '';
          }
        }
      }
      if (this.units == 'in') {
        if (input == 'dbhin') {
          if (this.inDbh.length > 0) {
            let conv = this.inDbh * 2.54;
            this.inDBHCm = Math.round(conv * 100) / 100;
          } else {
            this.inDBHCm = '';
            this.circumfrance = '';
            this.circCm = '';
          }
        } else if (input == 'circin') {
          if (this.circumfrance.length > 0) {
            let conv = this.circumfrance * 2.54;
            this.circCm = Math.round(conv * 100) / 100;
          } else {
            this.circCm = '';
          }
        }
      }
    },
    calcDBH() {
      //these get triggered anytime the circumfrance in or cm gets updated
      if (this.circumfrance.length > 0 || this.circumfrance > 0) {
        let pi = Math.PI;
        let dbh = this.circumfrance / pi;
        this.inDbh = Math.round(dbh * 100) / 100;
      } else {
        this.inDbh = '';
      }
    },
    calcDBHCm() {
      if (this.circCm.length > 0 || this.circumfrance > 0) {
        let pi = Math.PI;
        let dbh = this.circCm / pi;
        this.inDBHCm = Math.round(dbh * 100) / 100;
      } else {
        this.inDBHCm = '';
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.q-field {
  font-size: 16px !important;
}
</style>
