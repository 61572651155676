import { createStore, mapMutations } from 'vuex';
import axios from 'axios';
import authStore from './modules/authStore';
import userStore from './modules/userStore';
import apiAccessStore from './modules/apiAccessStore';
import s3Store from './modules/s3Store';
import treesStore from './modules/treesStore.js';
import qaqcStore from './modules/qaqcStore.js';

export default createStore({
  state: {
    app: {
      selectedView: 'intro',
      leftDrawer: { open: false, content: '' },
      screenWidth: '',
      showHelp: { url: '', filename: '' },
    },
    savingEdit: false,
    savingMessage: '',
  },
  mutations: {
    //state.app mutations
    updateShowHelp(state, obj) {
      state.app.showHelp = {};
      state.app.showHelp = obj;
    },
    updateSelectedView(state, val) {
      if (state.authStore.userInfo) {
        state.app.selectedView = val;
      } else {
        state.app.selectedView = 'login';
      }
    },
    updateLeftDrawer(state, obj) {
      state.app.leftDrawer = obj;
    },
    updateScreenWidth(state, int) {
      state.app.screenWidth = int;
    },
    updateSavingEdit(state, val) {
      // call this function from any PUT or POST with a value of 0, 1, or 2
      // this matches the order of the messages in the array below
      const message = ['Saving Edit', 'Edit Saved', 'Error - Try saving again'];
      // update the dialog text
      state.savingMessage = message[val];
      // set the dialog to visible
      state.savingEdit = true;
      // if the edit was successfully saved, hide the message after 3 seconds
      if (val == 1) {
        setTimeout(() => {
          state.savingEdit = false;
        }, '3000');
      }
      // if there was an error saving, hide the message after 5 seconds
      if (val == 2) {
        setTimeout(() => {
          state.savingEdit = false;
        }, '5000');
      }
    },
  },
  actions: {},
  modules: {
    authStore,
    userStore,
    apiAccessStore,
    s3Store,
    treesStore,
    qaqcStore,
  },
});
