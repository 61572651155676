<template>
  <div class="text-left">
    <q-item>
      <q-item-section avatar>
        <q-btn
          icon="sym_s_arrow_back"
          @click="this.$store.commit('updateExitQAQC')"
          unelevated
          color="blue-grey-9"
          square
          flat
        ></q-btn>
      </q-item-section>
      <q-item-section>
        <q-item-label class="text-body2"> Admin1_qaqc </q-item-label>
      </q-item-section>
      <q-item-section side class="">
        <q-item-label class="text-secondary text-caption">
          <q-linear-progress
            size="20px"
            :value="progressValue"
            color="blue-grey-9"
            class="q-mt-xs"
            style="width: 200px"
          >
            <div class="absolute-full flex flex-center">
              <q-badge
                color="white"
                text-color="primary"
                :label="progressLabel"
              />
            </div> </q-linear-progress
        ></q-item-label>
      </q-item-section>
    </q-item>
    <q-separator color="blue-grey-9"></q-separator>
    <QAQCMap></QAQCMap>
  </div>
</template>

<script>
import QAQCMap from './QAQCMap.vue';
export default {
  name: 'QAQC',
  components: { QAQCMap },
  computed: {
    progressValue() {
      let progress = this.$store.state.qaqcStore.percentComplete;
      let percent =
        Number(progress.visits_complete) / Number(progress.total_visits);
      return percent;
    },
    progressLabel() {
      let label = this.progressValue * 100;
      return label + '%';
    },
  },
  data() {
    return {
      qaqcProjects: [
        {
          name: 'Project 1080',
          date_added: '3/1/23',
          status: 'Active',
          id: 1,
        },
        {
          name: 'Park',
          date_added: '4/1/23',
          status: 'Active',
          id: 1,
        },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped></style>
