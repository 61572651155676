<template>
  <!--header tools projects-->
  <q-toolbar v-if="projectSelected == ''" class="q-mt-md q-mr-md">
    <q-tabs
      v-model="tab"
      class="text-blue-grey-9"
      active-color="primary"
      active-bg-color="white"
      indicator-color="secondary"
    >
      <q-tab
        :ripple="false"
        name="myprojects"
        label="My Projects"
        @click="this.$store.dispatch('getUsersProjects')"
      />
      <q-tab
        :ripple="false"
        name="findprojects"
        @click="this.getNearbyProjects()"
        label="Find Projects"
      />
    </q-tabs>
  </q-toolbar>
  <!--q-btn
      flat
      round
      icon="help"
      size="md"
      padding="sm"
      class="q-mb-md"
      color="primary"
      ><q-menu>
        <div class="q-ma-md">
          <p class="text-body1 text-blue-grey-9">Trees tab help</p>
          <q-separator spaced></q-separator>
          <b>How can I create a new project? </b>
          /more/ add a project in the dashboard
          <br /><br />
          <b>Why is my project not showing? </b>
          /more/ Check for archived
        </div>
      </q-menu></q-btn
    -toolbools tree list-->
  <q-toolbar v-if="projectSelected !== '' && !qaqc">
    <q-btn
      v-if="this.activeQAQC.length > 0"
      outline
      color="purple-5"
      @click="getQAQCTrees()"
      >QAQC</q-btn
    >
    <q-space></q-space>
    <div class="text-right q-mt-md q-mr-md">
      <q-btn flat no-caps icon="sort" class="q-mb-md"
        >&nbsp;Sort
        <q-menu>
          <q-list style="min-width: 100px">
            <q-item
              :class="this.sortValue == 'species' ? 'bg-green-1' : 'bg-white'"
              clickable
              v-close-popup
              @click="this.sortValue = 'species'"
            >
              <q-item-section>Latin name</q-item-section>
            </q-item>
            <q-item
              :class="this.sortValue == 'common' ? 'bg-green-1' : 'bg-white'"
              clickable
              v-close-popup
              @click="this.sortValue = 'common'"
            >
              <q-item-section>Common name</q-item-section>
            </q-item>
            <q-item
              :class="this.sortValue == 'dbh' ? 'bg-green-1' : 'bg-white'"
              clickable
              v-close-popup
              @click="this.sortValue = 'dbh'"
            >
              <q-item-section>DBH</q-item-section>
            </q-item>
            <q-item
              :class="this.sortValue == 'treetag' ? 'bg-green-1' : 'bg-white'"
              clickable
              v-close-popup
              @click="this.sortValue = 'treetag'"
            >
              <q-item-section>Tree Tag</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
      <q-checkbox
        v-model="showMyTrees"
        color="primary"
        inline
        dense
        type="checkbox"
        class="q-mb-md q-mr-md"
        label="My Trees"
      />
      <q-btn
        flat
        icon="help"
        size="md"
        padding="sm"
        class="q-mb-md"
        color="primary"
        ><q-menu>
          <div class="q-ma-md">
            <p class="text-body1 text-blue-grey-9">Trees tab help</p>
            <q-separator spaced></q-separator>
            <b>What are My Trees? </b>Selecting this checkbox will filter the
            trees list in each project to return only trees you have previously
            visited and recorded information on. These include trees you have
            added and any trees you have previously performed a health check,
            pest detection or tree care action on. <br /><br />
            <b>What are Nearby Trees? </b>By default only trees nearby your
            current location will be returned. These include all trees in the
            project that are approximately within 500 meters of your current
            location. Click the refresh button to refresh the nearby list. To
            see all trees in the project regardless of proximity, scroll to the
            bottom of the trees list and click the button 'Get all trees in
            project' <br /><br /><b>Why is my location not found?</b> There
            could be one or more reasons why your location is not being found.
            1. Make sure you have enabled location access for the browser. 2. Go
            to the map and click the locate me button in the upper left corner.
            3. Your device may need a moment to get your location. Try again in
            a little while.
          </div>
        </q-menu></q-btn
      >
    </div>
  </q-toolbar>

  <!--project list-->
  <q-list
    v-if="projectSelected == '' && this.tab == 'myprojects'"
    bordered
    class="rounded-borders scroll"
    style="height: calc(100vh - 200px)"
  >
    <q-item
      v-for="(proj, key) in projects"
      :key="key"
      @click="
        this.showNearby = true;
        this.projectSelected = proj;
        getTrees(proj.project_id);
        getQAQC(proj.project_id);
      "
      clickable
      class="bg-blue-grey-1 text-left"
      style="border-bottom: 1px solid darkgrey"
    >
      <q-item-section avatar>
        <q-avatar
          icon="sym_s_nature"
          color="blue-grey-1"
          text-color="primary"
        />
      </q-item-section>

      <q-item-section class="text-body1 text-bold"
        >{{ proj.project_name }}
        <br />
        <span class="text-caption"
          >{{ proj.tree_count }} trees</span
        ></q-item-section
      >

      <q-item-section v-if="proj.requests_pending == 1">
        <q-btn
          color="secondary"
          icon="sym_s_pending_actions"
          flat
          @click.stop.prevent="
            this.$store.dispatch('getMemberRequests', proj.project_id)
          "
          ><span class="text-caption">approve members</span></q-btn
        >
      </q-item-section>

      <q-item-section side>
        <q-icon name="chevron_right"></q-icon>
      </q-item-section>
    </q-item>
  </q-list>

  <!--find projects-->
  <div
    v-if="projectSelected == '' && this.tab == 'findprojects'"
    class="text-left q-ma-md"
  >
    <p class="text-body2 text-bold q-mb-none">Choose search option:</p>
    <q-radio
      v-model="searchOption"
      val="nearby"
      color="primary"
      label="Nearby"
    />
    <q-radio v-model="searchOption" val="name" label="Project Name" />
    <div v-if="searchOption == 'name'">
      <q-input
        class="q-my-md"
        outlined
        label="search by project name"
        v-model="searchterm"
        clearable
        @clear="this.$store.commit('updateProjectSearchResults', [])"
      >
        <template v-slot:after>
          <q-btn
            square
            size="lg"
            :color="searchterm ? 'primary' : 'white'"
            :text-color="searchterm ? 'white' : 'primary'"
            icon="search"
            unelevated
            @click="searchByProjectName()"
          />
        </template>
      </q-input>
      <q-list
        v-if="this.$store.state.userStore.projectSearchResults.length > 0"
      >
        <div class="bg-blue-grey-9 text-white text-subtitle2 q-pa-md">
          Search Results:
        </div>
        <q-scroll-area style="height: calc(100vh - 420px)">
          <q-item
            v-for="(proj, key) in this.$store.state.userStore
              .projectSearchResults"
            :key="key"
            class="text-left"
            style="border-top: 1px solid darkgreen"
          >
            <q-item-section avatar>
              <q-avatar icon="sym_s_nature" color="" text-color="primary" />
            </q-item-section>

            <q-item-section class="text-body1 text-bold"
              >{{ proj.project_name }}
              <br />

              <q-item-label class="text-caption">
                {{ proj.description }}
              </q-item-label></q-item-section
            >
            <q-item-section
              v-if="proj.project_user_relationship == 'AT Project Leader'"
            >
              <q-btn
                color="secondary"
                icon="sym_s_pending_actions"
                flat
                @click="
                  this.$store.dispatch('getMemberRequests', proj.project_id)
                "
                ><span class="text-caption">approve members</span></q-btn
              >
            </q-item-section>

            <q-item-section side>
              <q-btn
                v-if="proj.project_user_relationship == 'Ask to Join'"
                color="primary"
                label="Ask to Join"
                @click="joinProject(proj.project_id)"
              ></q-btn>
              <div
                v-if="proj.project_user_relationship == 'Approval Pending'"
                class="text-red text-caption"
              >
                APPROVAL PENDING
              </div>
              <div
                v-if="proj.project_user_relationship == 'AT Project Leader'"
                class="text-blue-grey-9 text-caption"
              >
                PROJECT LEADER
              </div>
              <div
                v-if="proj.project_user_relationship == 'Project Leader'"
                class="text-blue-grey-9 text-caption"
              >
                PROJECT LEADER
              </div>
              <div
                v-if="proj.project_user_relationship == 'Participant'"
                class="text-blue-grey-9 text-caption"
              >
                PARTICIPANT
              </div>
            </q-item-section>
          </q-item>
        </q-scroll-area>
      </q-list>
    </div>
    <q-list v-if="searchOption == 'nearby'">
      <div class="row bg-blue-grey-9 q-pa-sm q-mt-md">
        <div
          style="width: 120px"
          class="col-2 self-center text-white text-subtitle2"
        >
          Projects Within:
        </div>
        <div class="col-2 self-center">
          <q-select
            options-selected-class="text-white"
            dark
            dense
            :options="distanceOptions"
            v-model="distance"
            style="width: 95px"
            @update:model-value="getNearbyProjects()"
          ></q-select>
        </div>
      </div>
      <q-scroll-area style="height: calc(100vh - 350px)">
        <q-item
          v-for="(proj, key) in this.$store.state.userStore.nearbyProjects"
          :key="key"
          class="text-left"
          style="border-top: 1px solid darkgreen"
        >
          <q-item-section avatar>
            <q-avatar icon="sym_s_nature" color="" text-color="primary" />
          </q-item-section>

          <q-item-section class="text-body1 text-bold"
            >{{ proj.project_name }}
            <br />
            <q-item-label class="text-caption">
              {{ proj.description }}
            </q-item-label></q-item-section
          >
          <q-item-section
            v-if="proj.project_user_relationship == 'AT Project Leader'"
          >
            <q-btn
              color="secondary"
              icon="sym_s_pending_actions"
              flat
              @click="
                this.$store.dispatch('getMemberRequests', proj.project_id)
              "
              ><span class="text-caption">approve members</span></q-btn
            >
          </q-item-section>
          <q-item-section side>
            <q-btn
              v-if="proj.project_user_relationship == 'Ask to Join'"
              color="primary"
              label="Ask to Join"
              @click="joinProject(proj.project_id)"
            ></q-btn>
            <div
              v-if="proj.project_user_relationship == 'Approval Pending'"
              class="text-red text-caption"
            >
              APPROVAL PENDING
            </div>
            <div
              v-if="proj.project_user_relationship == 'AT Project Leader'"
              class="text-blue-grey-9 text-caption"
            >
              PROJECT LEADER
            </div>
            <div
              v-if="proj.project_user_relationship == 'Project Leader'"
              class="text-blue-grey-9 text-caption"
            >
              PROJECT LEADER
            </div>
            <div
              v-if="proj.project_user_relationship == 'Participant'"
              class="text-blue-grey-9 text-caption"
            >
              PARTICIPANT
            </div>
          </q-item-section>
        </q-item>
      </q-scroll-area>
    </q-list>
  </div>

  <!--tree list-->
  <div v-if="projectSelected !== '' && !qaqc">
    <q-item
      class="bg-blue-grey-1 text-left q-pb-lg"
      style="border-bottom: 1px solid darkgrey"
    >
      <q-item-section avatar>
        <q-btn
          round
          flat
          icon="arrow_back"
          color="blue-grey-1"
          text-color="primary"
          @click="this.projectSelected = ''"
        ></q-btn>
      </q-item-section>

      <q-item-section class="text-body1 text-bold"
        >{{ projectSelected.project_name }}
        <br />
        <span class="text-caption">{{ projectSelected.tree_count }} trees</span>
      </q-item-section>

      <q-item-section side>
        <div class="row">
          <q-icon
            size="sm"
            name="sym_s_water_drop"
            :color="
              this.projectSelected.tree_care == 1
                ? 'blue-grey-9'
                : 'blue-grey-2'
            "
          ></q-icon>
          <q-icon
            size="sm"
            name="sym_s_pest_control"
            :color="
              this.projectSelected.pest_detection == 1
                ? 'blue-grey-9'
                : 'blue-grey-2'
            "
          ></q-icon>
          <q-icon
            size="sm"
            name="sym_s_health_and_safety"
            :color="
              this.projectSelected.health_check == 1
                ? 'blue-grey-9'
                : 'blue-grey-1'
            "
          ></q-icon>
        </div>
      </q-item-section>
    </q-item>
    <div class="bg-blue-grey-9">
      <p
        class="text-overline text-white text-center q-mb-none q-mt-none q-pt-sm"
        v-if="showNearby && !showMyTrees"
      >
        {{ this.treesFiltered.length.toLocaleString() }} trees nearby
        <q-btn
          flat
          icon="refresh"
          size="sm"
          padding="sm"
          color="white"
          @click="this.getTrees(projectSelected.project_id)"
        ></q-btn>
      </p>
      <p
        class="text-overline text-white text-center q-mb-none q-mt-none q-pt-sm"
        v-if="showNearby && showMyTrees"
      >
        {{ this.treesFiltered.length.toLocaleString() }} visited trees nearby
        <q-btn
          flat
          icon="refresh"
          size="sm"
          padding="sm"
          color="white"
          @click="this.getTrees(proj.project_id)"
        ></q-btn>
      </p>
      <p
        class="text-white text-overline text-center q-mb-none q-pt-sm"
        v-if="!showNearby && !showMyTrees"
      >
        {{ this.treesFiltered.length.toLocaleString() }} trees in project
      </p>
      <p
        class="text-white text-overline text-center q-mb-none q-pt-sm"
        v-if="!showNearby && showMyTrees"
      >
        {{ this.treesFiltered.length.toLocaleString() }} visited trees
      </p>
      <div class="text-center bg-blue-grey-9 q-pb-md">
        <q-btn
          outline
          v-show="showNearby"
          color="white"
          label="Get all trees in project"
          size="sm"
          square
          class="text-overline"
          @click="
            this.showNearby = false;
            this.getTrees(this.projectSelected.project_id);
          "
        ></q-btn>

        <q-btn
          outline
          v-show="!showNearby"
          color="white"
          label="Get nearby trees"
          class="text-overline"
          size="sm"
          square
          @click="
            this.showNearby = true;
            this.getTrees(this.projectSelected.project_id);
          "
        ></q-btn>
      </div>
    </div>

    <div class="q-pa-md bg-blue-grey-9">
      <div v-if="loading" class="text-center">
        <q-spinner-dots color="white" size="2em" />
      </div>

      <q-virtual-scroll
        v-if="!loading"
        style="height: calc(100vh - 390px)"
        :items="treesFiltered"
        separator
        v-slot="{ item, index }"
      >
        <q-item
          class="text-left bg-white q-mb-xs"
          :key="index"
          v-ripple
          style="border-bottom: 1px solid lightgrey; max-height: 260px"
          clickable
          @click="
            this.$store.dispatch('getTreeById', [
              item.tree_id,
              projectSelected.project_id,
            ])
          "
        >
          <q-item-section
            avatar
            class="self-center highlight-and-fade q-pb-none q-pt-xs"
            style="width: 70px"
          >
            <div class="text-primary">
              <q-btn v-if="item.z_score" round @click.stop="noZscore(item)">
                <q-knob
                  readonly
                  v-model="item.z_score"
                  :max="10"
                  show-value
                  size="45px"
                  :thickness="0.22"
                  :color="
                    item.z_score < 4
                      ? 'green'
                      : item.z_score > 3 && item.z_score < 8
                      ? 'orange'
                      : 'red'
                  "
                  :class="{
                    'text-green': item.z_score < 4,
                    'text-orange': item.z_score > 3 && item.z_score < 8,
                    'text-red': item.z_score > 7,
                  }"
                  track-color="grey-4"
                  font-size="16px"
                />
              </q-btn>
              <q-btn
                v-else
                round
                size="27px"
                padding="0px"
                icon="far fa-circle-question"
                style="height: 45px; width: 45px; color: #ccc"
                @click.stop="noZscore(item)"
              /></div
          ></q-item-section>
          <q-item-section class="col">
            <div class="text-caption text-grey"></div>
            <div class="q-mt-xs q-mb-none q-pb-none text-body2 text-bold">
              <q-icon
                v-if="item.created_tree"
                name="sym_s_person"
                size="xs"
                color="grey-9"
                class="q-mr-sm"
              ></q-icon>
              {{ item.tree_name }}
            </div>
            <div class="text-body2 q-mt-xs q-mb-none q-pb-none">
              {{ item.common_name }}
            </div>
            <div class="text-caption text-grey-9 q-mt-none q-pt-none">
              {{ item.scientific_name }}
            </div>

            <div></div>
          </q-item-section>
          <q-item-section avatar class="col-1" style="width: 70px">
            <div class="text-grey-8 q-gutter-xs">
              <q-btn
                size="lg"
                color="primary"
                flat
                dense
                round
                icon="sym_s_home_pin"
                @click.stop="
                  this.$store.dispatch('getTreeById_openMap', [
                    item.tree_id,
                    projectSelected.project_id,
                  ])
                "
              ></q-btn>
            </div>
          </q-item-section>
        </q-item>
      </q-virtual-scroll>
    </div>
  </div>

  <!--qaqc-->
  <QAQC v-if="projectSelected !== '' && qaqc"></QAQC>

  <!--heath check counts-->
  <q-dialog v-model="showHealthCheckCounts">
    <health-check-count
      :speciesHealthCheck="healthcheckCounts[0].species_health_checks"
      :treeHealthCheck="healthcheckCounts[0].tree_health_checks"
    ></health-check-count>
  </q-dialog>
</template>
<script>
import * as turf from '@turf/turf';
import HealthCheckCount from './HealthCheckCount.vue';
import QAQC from './QAQC.vue';

export default {
  name: 'Trees',
  components: { HealthCheckCount, QAQC },

  computed: {
    tab: {
      get() {
        return this.$store.state.userStore.projectsTab;
      },
      set(value) {
        this.$store.commit('updateProjectsTab', value);
      },
    },
    projects() {
      let projects = this.$store.state.userStore.usersProjects;
      let visibleProj = projects.filter(function (val) {
        return val.visible == 1;
      });
      return visibleProj;
    },
    activeQAQC() {
      let qaqcbyproj = this.$store.state.qaqcStore.qaqcByProject;
      let active = [];
      for (var i = 0; i < qaqcbyproj.length; i++) {
        if (qaqcbyproj[i].completed_date !== '') {
          active.push(qaqcbyproj[i]);
        }
      }
      console.log(active);
      return active;
    },
    healthcheckCounts() {
      return this.$store.state.treesStore.healthcheckCounts;
    },
    projectNameCased(proj) {
      return (
        proj.toLowerCase().charAt(0).toUpperCase() + proj.toLowerCase().slice(1)
      );
    },
    projectTrees() {
      return this.$store.state.treesStore.treesInProject;
    },
    projectTreesNearby() {
      return this.$store.state.treesStore.treesInProjectNearMe;
    },
    userLocation() {
      return this.$store.state.userStore.usersLocation;
    },
    treesFiltered() {
      //deep copy object
      let nearby = this.projectTreesNearby;
      let all = this.projectTrees;
      let data = '';
      this.showNearby ? (data = nearby) : (data = all);
      let filtered = [];
      if (data.length > 0) {
        filtered = JSON.parse(JSON.stringify(data));

        if (this.showMyTrees) {
          let owned = filtered.filter(function (tree) {
            return tree.created_tree || tree.worked_on_tree;
          });
          filtered = owned;
        }
        if (this.sortValue == 'species') {
          filtered.sort((a, b) =>
            a.scientific_name.localeCompare(b.scientific_name)
          );
        }
        if (this.sortValue == 'common') {
          filtered.sort((a, b) => a.common_name.localeCompare(b.common_name));
        }
        if (this.sortValue == 'treetag') {
          filtered.sort((a, b) => a.tree_name.localeCompare(b.tree_name));
        }
        if (this.sortValue == 'dbh') {
          filtered.sort((a, b) => parseFloat(a.dbh) - parseFloat(b.dbh));
        }
      }
      this.loading = false;
      return filtered;
    },
    exitQAQC() {
      return this.$store.state.qaqcStore.exitQAQC;
    },
  },

  mounted() {
    this.$store.dispatch('getUsersProjects');
    this.getNearbyProjects();
  },
  activated() {
    this.$store.dispatch('getUsersProjects');
    this.getNearbyProjects();
  },
  watch: {
    exitQAQC() {
      this.qaqc = false;
    },
  },

  data() {
    return {
      qaqc: false,
      projectSelected: '',
      searchOption: 'nearby',
      loading: false,
      scrollTargetRef: '',
      activeTrees: [],
      sortValue: 'common',
      treeView: 1,
      showMyTrees: false,
      showSpecies: false,
      showNearby: true,
      activeProjectIndex: '',
      healthIndex: 0,
      showHealthCheckCounts: false,
      results: [],
      searchterm: '',
      distance: { label: '2 miles', value: 2 },
      distanceOptions: [
        { label: '2 miles', value: 2 },
        { label: '10 miles', value: 10 },
        { label: '50 miles', value: 50 },
      ],
    };
  },
  methods: {
    async goToLatLon(item) {
      await this.$store.commit('updateGoToLatLon', {
        lat: item.latitude,
        lon: item.longitude,
      });
      await this.$store.commit('updateSelectedView', 'map');
      await this.$store.commit('updateLeftDrawer', { open: false });
    },
    getTrees(id) {
      this.loading = true;
      if (this.showNearby) {
        // if show nearby try to get extent
        let extent = this.getUserbuffer(0.03);
        console.log(extent);
        if (extent) {
          this.$store.dispatch('getAllTreesInProjectNearMe', [id, extent]);
        } else {
          //if no location default to all trees in project
          this.showNearby = false;
          this.$store.dispatch('getAllTreesInProject', id);
          this.notify(
            'Unable to access user location to get nearby trees. Showing all trees.'
          );
        }
      } else {
        this.showNearby = false;
        this.$store.dispatch('getAllTreesInProject', id);
      }
    },
    getQAQC(proj_id) {
      this.$store.dispatch('getQAQCByProject', proj_id);
    },
    getQAQCTrees() {
      this.qaqc = true;
      console.log('coming from trees');
      this.$store.dispatch('getQAQCTrees', {
        qaqcId: this.activeQAQC[0].qaqc_id,
        projectId: this.activeQAQC[0].project_id,
      });
    },
    getUserbuffer(miles) {
      // first get user location from map then create 500 m (diamond)buffer
      if (Object.keys(this.userLocation).length > 0) {
        let lon = this.userLocation.coords.longitude;
        let lat = this.userLocation.coords.latitude;
        const p = turf.point([lon, lat]);
        //using 200 miles for test to nyc.  update with new value
        let poly = turf.buffer(p, miles, { units: 'miles', steps: 1 });
        let polyBuffer = turf.featureCollection([poly]);
        let coords = polyBuffer.features[0].geometry.coordinates[0];
        let extent = '';
        for (var i = 0; i < coords.length; i++) {
          extent = extent + coords[i][1] + ' ' + coords[i][0] + ',';
        }
        //remove last comma
        extent = extent.substring(0, extent.length - 1);

        return extent;
      } else {
        return '';
      }
    },
    notify(message) {
      this.$q.notify({
        message: message,

        actions: [
          {
            color: 'white',
            icon: 'close',
            handler: () => {
              //do someting
            },
          },
        ],
      });
    },
    async noZscore(tree) {
      await this.$store.dispatch('getHealthcheckCounts', [
        tree.sub_id,
        tree.tree_id,
      ]);
      this.showHealthCheckCounts = true;
    },
    getNearbyProjects() {
      let extent = this.getUserbuffer(this.distance.value);
      let polyText = `POLYGON((${extent}))`;
      this.$store.dispatch('getNearbyProjects', polyText);
    },
    searchByProjectName() {
      let searchterm = this.searchterm;
      this.$store.dispatch('getProjectByName', searchterm);
    },
    joinProject(projectId) {
      let extent = this.getUserbuffer(this.distance.value);
      console.log(extent);
      let polyText = `POLYGON((${extent}))`;
      let searchterm = this.searchterm;
      this.$store.dispatch('joinProject', {
        projectId: projectId,
        polyText: polyText,
        searchterm: searchterm,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<style></style>
