<template>
  <div v-if="showSpinner" class="row items-center q-mt-sm">
    <q-spinner-rings color="primary" size="4em" />
    <div class="text-primary text-h6">..Finding Location</div>
  </div>
  <div class="q-header">
    <!-- <div class="text-center q-my-xs">
      {{ treesData.features.length }} trees in view <br />
    </div> -->
    <div v-if="showAddNewProject">
      <div
        class="add-tree-instructions shadow-2 rounded-borders bg-white q-py-sm q-px-md"
      >
        <div class="">
          <p class="text-h6 q-mb-none">Create Project</p>
          <p class="q-mb-none">Use map to set project extent</p>

          <div class="text-caption q-mb-sm">
            <q-checkbox
              dense
              true-value="1"
              false-value="0"
              size="sm"
              v-model="
                this.$store.state.userStore.newProjectExtent.includeTrees
              "
            ></q-checkbox
            >&nbsp; include
            {{
              treesData.features.length == 3000
                ? '3000+'
                : treesData.features.length
            }}
            trees in extent
          </div>
        </div>
        <q-btn
          @click="submitNewProject(treesData.features.length)"
          color="white"
          label="Set"
          class="q-mr-sm shadow-1"
          text-color="primary"
          style="width: 90px"
        />
        <q-btn
          @click="showAddNewProject = false"
          color="white"
          label="Cancel"
          text-color="red"
          class="shadow-1"
          style="width: 90px"
        />
      </div>
    </div>
    <div v-if="showAddNewTree">
      <div
        class="add-tree-instructions shadow-2 rounded-borders bg-white q-py-sm q-px-md"
      >
        <div class="text-h6">Choose tree location</div>
        <div class="q-mb-sm">
          Move
          <q-icon
            style="font-size: 24px"
            name="far fa-circle-xmark"
            color="b"
          />
          over tree location
        </div>
        <q-btn
          @click="submitNewTree"
          color="white"
          label="Set"
          class="q-mr-sm shadow-1"
          text-color="primary"
          style="width: 90px"
        />
        <q-btn
          @click="showAddNewTree = false"
          color="white"
          label="Cancel"
          text-color="red"
          class="shadow-1"
          style="width: 90px"
        />
      </div>
      <q-icon
        name="far fa-circle-xmark"
        class="map-center-selector"
        color="white"
      />
      <!-- <div class="add-tree-buttons">
          <q-btn
            @click="submitNewTree"
            color="white"
            label="Submit"
            class="q-mr-md shadow-2"
            text-color="primary"
          />
          <q-btn
            @click="showAddNewTree = false"
            color="white"
            label="Cancel"
            text-color="red"
            class="shadow-2"
          />
        </div> -->
    </div>
    <div v-if="showMoveTree">
      <div
        class="add-tree-instructions shadow-2 rounded-borders bg-white q-py-sm q-px-md"
      >
        <div class="text-h6">Set new location</div>
        <div class="q-mb-sm">
          Move
          <q-icon
            style="font-size: 24px"
            name="far fa-circle-xmark"
            color="b"
          />
          over tree location
        </div>
        <q-btn
          @click="moveTree"
          color="white"
          label="Set"
          class="q-mr-sm shadow-1"
          text-color="primary"
          style="width: 90px"
        />
        <q-btn
          @click="showMoveTree = false"
          color="white"
          label="Cancel"
          text-color="red"
          class="shadow-1"
          style="width: 90px"
        />
      </div>
      <q-icon
        name="far fa-circle-xmark"
        class="map-center-selector"
        color="white"
      />
      <!-- <div class="add-tree-buttons">
          <q-btn
            @click="submitNewTree"
            color="white"
            label="Submit"
            class="q-mr-md shadow-2"
            text-color="primary"
          />
          <q-btn
            @click="showAddNewTree = false"
            color="white"
            label="Cancel"
            text-color="red"
            class="shadow-2"
          />
        </div> -->
    </div>
    <div
      v-if="!showAddNewTree && !showAddNewProject && !showMoveTree"
      class="tree-count-message shadow-2 rounded-borders bg-white q-py-sm q-px-md text-subtitle2"
    >
      {{ treesData.features.length }} trees in view
    </div>
    <div
      id="map"
      :style="
        showAddNewProject
          ? 'z-index: 99; border: 4px solid red'
          : 'z-index: 99;'
      "
    ></div>

    <!--q-btn
      @click="showAddNewTree = true"
      color="white"
      text-color="primary"
      icon="add"
      class="shadow-2"
      style="
        position: absolute;
        z-index: 100;
        top: 55px;
        right: 10px;
        height: 35px;
        width: 35px;
      "
    /-->
    <q-fab
      square
      dense
      color="white"
      text-color="primary"
      icon="add"
      direction="left"
      :style="
        showAddNewProject
          ? 'position: absolute; z-index: 100; top: 59px; right: 14px'
          : 'position: absolute; z-index: 100; top: 55px; right: 10px'
      "
      padding="sm"
    >
      <q-fab-action
        padding="xs"
        color="white"
        text-color="primary"
        square
        label="Add New Tree"
        @click="
          showAddNewProject = false;
          showAddNewTree = true;
        "
        icon="sym_s_nature"
      />
      <q-fab-action
        padding="xs"
        color="white"
        text-color="primary"
        square
        label="Create Project"
        @click="
          showAddNewTree = false;
          showAddNewProject = true;
        "
        icon="sym_s_folder"
      ></q-fab-action>
    </q-fab>
  </div>
  <q-dialog v-model="prompt" persistent>
    <q-card style="min-width: 350px">
      <q-card-section>
        <div class="text-body1">
          This tree is associated with more than one of your projects. Select a
          project to view tree home page:
        </div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <q-select
          dense
          v-model="selectedProject"
          autofocus
          :options="projects"
          option-label="project_name"
          label="Select a project"
          @update:model-value="
            this.$store.commit('updateSelectedProject', selectedProject);
            this.$store.dispatch('getTreeById', [
              this.selectedTreeId,
              this.selectedProject.project_id,
            ]);
            this.prompt = false;
          "
        />
      </q-card-section>

      <q-card-actions align="right" class="text-primary">
        <q-btn flat label="Cancel" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import mapboxgl from '/node_modules/mapbox-gl/dist/mapbox-gl.js';
let mapbox = {
  map: '',
  geolocate: '',
  first: 'yes',
  flying: false,
};
export default {
  name: 'Map',
  components: {},
  computed: {
    treesData() {
      return this.$store.state.treesStore.treesGeoJson;
    },
    selectedTree() {
      return this.$store.state.treesStore.selectedTree;
    },
    goToLatLon() {
      return this.$store.state.treesStore.goToLatLon;
    },
    showAddNewTree: {
      get() {
        return this.$store.state.treesStore.showAddNewTree;
      },
      set(value) {
        this.$store.commit('updateShowAddNewTree', value);
      },
    },
    showMoveTree: {
      get() {
        return this.$store.state.treesStore.moveTreeMode;
      },
      set(bool) {
        this.$store.commit('updateMoveTreeMode', bool);
      },
    },
  },
  watch: {
    treesData(newVal, oldVal) {
      this.updateTreesLayer();
    },
    goToLatLon(newVal, oldVal) {
      this.flyToLatLon(newVal);
    },
  },
  data() {
    return {
      map: '',
      pointProperties: '',
      prompt: false,
      projects: [],
      selectedProject: '',
      showAddNewProject: false,
      showSpinner: false,
    };
  },
  mounted() {
    this.showSpinner = true;
    if (mapbox.map == '') {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.successCallback,
          this.errorCallback
        );
      } else {
        this.showSpinner = false;
        console.log('Geolocation is not supported by this browser.');
      }
    }
    /*this.$q.notify({
      message:
        "Looks like you don''t have any trees in your project yet.  Would you like to see instructions for adding a tree?.",
      color: 'white',
      actions: [
        {
          color: 'primary',
          label: 'Show Me',
          handler: () => {
            //do something else
          },
        },
      ],
    });*/
  },

  methods: {
    successCallback(position) {
      this.showSpinner = false;
      const lat = position.coords.latitude;
      const long = position.coords.longitude;
      let coords = [long, lat, 18];
      this.$store.commit('updateUsersStartLocation', coords);
      this.createMap(coords);
    },
    errorCallback(error) {
      this.showSpinner = false;
      let coords = [-101.299591, 47.116386, 3];
      this.createMap(coords);
      console.log(
        'Error occurred while retrieving the location:',
        error.message
      );
    },
    createMap(coords) {
      // First, create a new Mapbox map and set the center and zoom level
      mapboxgl.accessToken =
        'pk.eyJ1IjoidG5jbWFwYm94IiwiYSI6ImNsYmZpNzE0MDA2aHozbm1wazV1aWp3NHUifQ.7plkZIxeS9mzUXB06i-CLg';

      mapbox.map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/satellite-streets-v11',
        center: [coords[0], coords[1]],
        zoom: coords[2],
      });

      //add map controls during create
      this.addMapControls();

      let _this = this;
      mapbox.map.on('load', function () {
        //once map is loaded tells us the controls have been added so
        //then trigger
        mapbox.geolocate.trigger();
        _this.getMapBoundingBox();
        _this.addTreeLayerAndSource();
      });
      mapbox.map.on('click', 'trees-layer', async (e) => {
        this.mapClick(e);
      });

      mapbox.map.on('moveend', async function () {
        await _this.getMapBoundingBox();
        await _this.updateTreesLayer();
      });
    },

    clearHighlight() {
      if (typeof mapbox.map.getLayer('selected-tree') !== 'undefined') {
        mapbox.map.removeLayer('selected-tree');
        mapbox.map.removeSource('selected-tree');
      }
    },
    highlightPoint(feature) {
      mapbox.map.addSource('selected-tree', {
        type: 'geojson',
        data: feature,
      });
      mapbox.map.addLayer({
        id: 'selected-tree',
        type: 'circle',
        source: 'selected-tree',
        paint: {
          'circle-radius': 8,
          'circle-opacity': 0,
          'circle-stroke-width': 3,
          'circle-stroke-color': '#000',
        },
      });
    },
    mapClick(e) {
      let treeId = e.features[0].properties.tree_id;
      let projects = JSON.parse(e.features[0].properties.tree_in_project);
      let projectId = null;
      var features = mapbox.map.queryRenderedFeatures(e.point, {
        layers: ['trees-layer'],
      });
      if (projects.length > 0) {
        if (projects.length > 1) {
          this.selectedProject = '';
          this.prompt = true;
          this.projects = projects;
          this.selectedTreeId = treeId;
          this.$store.commit('updateTreeProjectList', this.projects);
          // add logic for the user to choose between the multiple projects and send one ID to the database.
          //exit the function here, user selects a project and the dispatch function is called on select update
          var feature = features[0];
          this.clearHighlight();
          console.log(feature);
          this.highlightPoint(feature);
          return;
        } else {
          // only one project so send this ID to the database
          projectId = projects[0].project_id;
        }
      }
      this.$store.dispatch('getTreeById', [treeId, projectId]);

      this.clearHighlight();
      var feature = features[0];
      this.highlightPoint(feature);
    },
    async getMapBoundingBox() {
      let boundingBoxString = `${mapbox.map.getBounds().getNorthWest().lat} ${
        mapbox.map.getBounds().getNorthWest().lng
      }, ${mapbox.map.getBounds().getNorthEast().lat} ${
        mapbox.map.getBounds().getNorthEast().lng
      }, ${mapbox.map.getBounds().getSouthEast().lat} ${
        mapbox.map.getBounds().getSouthEast().lng
      }, ${mapbox.map.getBounds().getSouthWest().lat} ${
        mapbox.map.getBounds().getSouthWest().lng
      }, ${mapbox.map.getBounds().getNorthWest().lat} ${
        mapbox.map.getBounds().getNorthWest().lng
      }`;

      // set bounding box to state
      this.$store.commit('updateBoundingBox', boundingBoxString);
      this.$store.dispatch('getTreesByMapExtent');
    },
    async submitNewTree() {
      let center = mapbox.map.getCenter();
      let latitude = center.lat;
      let longitude = center.lng;
      console.log(latitude, longitude);
      await this.$store.commit('updateLatLong', [latitude, longitude]);
      await this.$store.commit('updateLeftDrawer', {
        open: true,
        content: 'addtree',
      });
    },
    async moveTree() {
      let center = mapbox.map.getCenter();
      let treeId = this.$store.state.treesStore.selectedTree.tree_id;
      let obj = { latitude: center.lat, longitude: center.lng, treeId: treeId };
      await this.$store.dispatch('editTreeLocation', obj);
      await this.getMapBoundingBox();
      await this.updateTreesLayer();
      let geojson = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [center.lng, center.lat],
        },
        properties: {
          title: 'My Marker',
          description: 'This is a custom marker',
        },
      };
      this.clearHighlight();
      this.highlightPoint(geojson);
      this.showMoveTree = false;
    },
    async submitNewProject(treeCount) {
      let polyText = `POLYGON((${mapbox.map.getBounds().getNorthWest().lat} ${
        mapbox.map.getBounds().getNorthWest().lng
      }, ${mapbox.map.getBounds().getNorthEast().lat} ${
        mapbox.map.getBounds().getNorthEast().lng
      }, ${mapbox.map.getBounds().getSouthEast().lat} ${
        mapbox.map.getBounds().getSouthEast().lng
      }, ${mapbox.map.getBounds().getSouthWest().lat} ${
        mapbox.map.getBounds().getSouthWest().lng
      }, ${mapbox.map.getBounds().getNorthWest().lat} ${
        mapbox.map.getBounds().getNorthWest().lng
      }))`;

      this.$store.commit('updateNewProjectExtent', {
        polyText: polyText,
        treeCount: treeCount,
        includeTrees: this.$store.state.userStore.newProjectExtent.includeTrees,
      });
      this.showAddNewProject = false;
      this.$store.commit('updateLeftDrawer', {
        open: true,
        content: 'newproject',
      });

      //remove sending.
    },

    async updateTreesLayer() {
      var source = mapbox.map.getSource('trees-data');
      source.setData(this.treesData);
    },
    addTreeLayerAndSource() {
      mapbox.map.addSource('trees-data', {
        type: 'geojson',
        data: this.treesData,
        generateId: true,
      });
      mapbox.map.addLayer({
        id: 'trees-layer',
        type: 'circle',
        source: 'trees-data',
        paint: {
          'circle-radius': 8,
          'circle-color': [
            'match', // Use the match expression
            ['get', 'symbology'], // Get the value of the "type" property
            1,
            'red', // If the "type" value is "Type A", use a red color
            0,
            'blue', // If the "type" value is "Type B", use a green color
            'green', // If the "type" value is,
            // 'case',
            // ['boolean', ['feature-state', 'hover'], false],
            // 'yellow',
            // 'red',
          ],
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff',
        },
      });
    },
    addMapControls() {
      // const that = this;
      // add map controls ************************************
      mapbox.geolocate = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: true,
        showUserLocation: true,
      });
      // console.log('after add geolocate');

      mapbox.map.addControl(mapbox.geolocate, 'top-right');
      // super hacky code to show the users location via the blue GPS dot
      // the geolocate.trigger() was not working
      /*setTimeout(function () {
        const elementToClick = document.querySelector(
          '.mapboxgl-ctrl-geolocate'
        );
        if (elementToClick) {
          elementToClick.click();
          console.log('trigger geolocate');
        }
      }, 500);*/

      // let init = 0;

      // when a geolocate event occurs.
      mapbox.geolocate.on('geolocate', (position) => {
        console.log(position);
        // You can access the user's current location here
        var userLocation = position.coords;
        var coordinates = position.coords;
        if (mapbox.first === 'yes') {
          mapbox.map.setZoom(18);
          mapbox.first = 'no';
        }
        console.log('geolocate');
        // if (init == 0) {
        //   that.map.flyTo({
        //     center: [coordinates.longitude, coordinates.latitude],
        //     zoom: 18,
        //   });
        // }
        // init = 1;

        //this passes the users location to the store
        this.$store.commit('updateUsersLocation', position);
      });
      //a custom function could check the last location and deterimin
      //if the distance meets the criteria for moving the dot or we can
      //choose to move the dot only on a threshold of accuracy.  We might
      //have to use a custom dot instead of the widget because I dont know
      //if we have control over the movement of the dot through the widget?
      //this needs more work and testing. Some other events below:
      /*
      // when a trackuserlocationstart or trackuserlocationend event occurs.
      this.geolocate.on('trackuserlocationstart', () => {
        console.log('A trackuserlocationstart event has occurred.');
      });
      this.geolocate.on('trackuserlocationend', () => {
        console.log('A trackuserlocationend event has occurred.');
      });*/

      // console.log(mapboxgl);
      // this.map.addControl(new mapboxgl.StyleSwitcherControl(), 'top-right');
    },
    flyToLatLon(obj) {
      mapbox.map.flyTo({
        center: [obj.lon, obj.lat],
        essential: true, // this animation is considered essential with respect to prefers-reduced-motion
      });
      let geojson = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [obj.lon, obj.lat],
        },
        properties: {
          title: 'My Marker',
          description: 'This is a custom marker',
        },
      };
      this.clearHighlight();
      this.highlightPoint(geojson);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="mapbox-gl/dist/mapbox-gl.css"></style>
<style>
#map {
  height: calc(100vh - 124px);
}
.outlineToggle {
  border: 1px solid green;
}
.map-center-selector {
  position: absolute !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  font-size: 30px !important;
}
.add-tree-instructions {
  position: absolute;
  z-index: 100;
  left: 50%;
  top: 10px;
  width: 230px;
  margin-left: -115px;
}
.add-tree-buttons {
  position: absolute;
  z-index: 100;
  left: 50%;
  bottom: 10px;
  width: 192px;
  margin-left: -96px;
}
.tree-count-message {
  position: absolute;
  z-index: 100;
  left: 50%;
  top: 10px;
  width: 150px;
  margin-left: -75px;
}
.mapboxgl-ctrl-group button {
  height: 40px !important;
  width: 40px !important;
}
</style>
