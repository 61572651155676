<template>
  <div v-if="showSpinner" class="row items-center q-mt-sm">
    <q-spinner-rings color="primary" size="4em" />
    <div class="text-primary text-h6">..Finding Location</div>
  </div>
  <div style="position: absolute; z-index: 100; top: 65px; left: 15px">
    <q-btn-toggle
      v-model="filter"
      unelevated
      toggle-color="blue-grey-9"
      color="white"
      text-color="primary"
      :options="[
        { label: 'All', value: 'all', slot: 'all' },
        { value: 'todo', slot: 'todo' },
        {
          value: 'done',
          slot: 'done',
        },
      ]"
    >
      <template v-slot:all> </template>
      <template v-slot:todo>
        <q-icon left name="circle" color="purple-5" />
        <div class="row items-center no-wrap">
          <div class="text-center">TO DO</div>
        </div>
      </template>

      <template v-slot:done>
        <q-icon left name="circle" color="grey" />
        <div class="row items-center no-wrap">
          <div class="text-center">Done</div>
        </div>
      </template>
    </q-btn-toggle>
  </div>
  <div
    id="map2"
    :style="
      showAddNewProject ? 'z-index: 99; border: 4px solid red' : 'z-index: 99;'
    "
  ></div>
</template>

<script>
import mapboxgl from '/node_modules/mapbox-gl/dist/mapbox-gl.js';
let mapbox2 = {
  map: '',
  geolocate: '',
  first: 'yes',
  flying: false,
};
export default {
  name: 'QAQCMap',
  components: {},
  computed: {
    activeQAQC() {
      let qaqcbyproj = this.$store.state.qaqcStore.qaqcByProject;
      let active = [];
      for (var i = 0; i < qaqcbyproj.length; i++) {
        if (qaqcbyproj[i].completed_date !== '') {
          active.push(qaqcbyproj[i]);
        }
      }

      return active;
    },
    treesData() {
      let trees = this.$store.state.qaqcStore.qaqcTreesGeoJson.features;
      let filtered = { type: 'FeatureCollection', features: [] };
      if (this.filter == 'all') {
        filtered = this.$store.state.qaqcStore.qaqcTreesGeoJson;
      }
      if (this.filter == 'done') {
        trees.filter((tree) => {
          if (tree.properties.symbology == 1) {
            filtered.features.push(tree);
          }
        });
      }
      if (this.filter == 'todo') {
        trees.filter((tree) => {
          if (tree.properties.symbology == 0) {
            filtered.features.push(tree);
          }
        });
      }

      return filtered;
    },
    selectedTree() {
      return this.$store.state.treesStore.selectedTree;
    },
    goToLatLon() {
      return this.$store.state.treesStore.goToLatLon;
    },
  },
  watch: {
    treesData(newVal, oldVal) {
      var source = mapbox2.map.getSource('trees-data-qaqc');
      source.setData(this.treesData);
    },

    goToLatLon(newVal, oldVal) {
      this.flyToLatLon(newVal);
    },
  },
  data() {
    return {
      map: '',
      pointProperties: '',
      prompt: false,
      projects: [],
      selectedProject: '',
      showAddNewProject: false,
      showSpinner: false,
      filter: 'all',
    };
  },
  mounted() {
    let coords = this.$store.state.userStore.usersStartLocation;
    this.createMap(coords);
  },

  methods: {
    createMap(coords) {
      // First, create a new Mapbox map and set the center and zoom level
      mapboxgl.accessToken =
        'pk.eyJ1IjoidG5jbWFwYm94IiwiYSI6ImNsYmZpNzE0MDA2aHozbm1wazV1aWp3NHUifQ.7plkZIxeS9mzUXB06i-CLg';

      mapbox2.map = new mapboxgl.Map({
        container: 'map2',
        style: 'mapbox://styles/mapbox/satellite-streets-v11',
        center: [coords[0], coords[1]],
        zoom: coords[2],
      });

      //add map controls during create
      this.addMapControls();

      let _this = this;
      mapbox2.map.on('load', function () {
        //once map is loaded tells us the controls have been added so
        //then trigger
        _this.addTreeLayerAndSource();
        mapbox2.geolocate.trigger();
        //_this.getMapBoundingBox();
      });
      mapbox2.map.on('click', 'trees-layer', async (e) => {
        this.mapClick(e);
      });

      mapbox2.map.on('moveend', async function () {
        //await _this.getMapBoundingBox();
        await _this.updateTreesLayer();
      });
    },

    clearHighlight() {
      if (typeof mapbox2.map.getLayer('selected-tree') !== 'undefined') {
        mapbox2.map.removeLayer('selected-tree');
        mapbox2.map.removeSource('selected-tree');
      }
    },
    highlightPoint(feature) {
      mapbox2.map.addSource('selected-tree', {
        type: 'geojson',
        data: feature,
      });
      mapbox2.map.addLayer({
        id: 'selected-tree',
        type: 'circle',
        source: 'selected-tree',
        paint: {
          'circle-radius': 8,
          'circle-opacity': 0,
          'circle-stroke-width': 3,
          'circle-stroke-color': '#000',
        },
      });
    },
    mapClick(e) {
      let treeId = e.features[0].properties.tree_id;
      // let projects = JSON.parse(e.features[0].properties.tree_in_project);
      let projectId = this.activeQAQC[0].project_id;
      var features = mapbox2.map.queryRenderedFeatures(e.point, {
        layers: ['trees-layer'],
      });
      this.$store.commit('updateSelectedQAQCVisit', e.features[0].properties);
      this.$store.dispatch('getQAQCTreeById', [treeId, projectId]);

      this.clearHighlight();
      var feature = features[0];
      this.highlightPoint(feature);
    },
    async getMapBoundingBox() {
      let boundingBoxString = `${mapbox2.map.getBounds().getNorthWest().lat} ${
        mapbox2.map.getBounds().getNorthWest().lng
      }, ${mapbox2.map.getBounds().getNorthEast().lat} ${
        mapbox2.map.getBounds().getNorthEast().lng
      }, ${mapbox2.map.getBounds().getSouthEast().lat} ${
        mapbox2.map.getBounds().getSouthEast().lng
      }, ${mapbox2.map.getBounds().getSouthWest().lat} ${
        mapbox2.map.getBounds().getSouthWest().lng
      }, ${mapbox2.map.getBounds().getNorthWest().lat} ${
        mapbox2.map.getBounds().getNorthWest().lng
      }`;

      // set bounding box to state
      this.$store.commit('updateBoundingBox', boundingBoxString);
      this.$store.dispatch('getTreesByMapExtent');
    },
    async submitNewTree() {
      let center = mapbox2.map.getCenter();
      let latitude = center.lat;
      let longitude = center.lng;

      await this.$store.commit('updateLatLong', [latitude, longitude]);
      await this.$store.commit('updateLeftDrawer', {
        open: true,
        content: 'addtree',
      });
    },
    async moveTree() {
      let center = mapbox2.map.getCenter();
      let treeId = this.$store.state.treesStore.selectedTree.tree_id;
      let obj = { latitude: center.lat, longitude: center.lng, treeId: treeId };
      await this.$store.dispatch('editTreeLocation', obj);
      await this.getMapBoundingBox();
      await this.updateTreesLayer();
      let geojson = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [center.lng, center.lat],
        },
        properties: {
          title: 'My Marker',
          description: 'This is a custom marker',
        },
      };
      this.clearHighlight();
      this.highlightPoint(geojson);
      this.showMoveTree = false;
    },
    async submitNewProject(treeCount) {
      let polyText = `POLYGON((${mapbox2.map.getBounds().getNorthWest().lat} ${
        mapbox2.map.getBounds().getNorthWest().lng
      }, ${mapbox2.map.getBounds().getNorthEast().lat} ${
        mapbox2.map.getBounds().getNorthEast().lng
      }, ${mapbox2.map.getBounds().getSouthEast().lat} ${
        mapbox2.map.getBounds().getSouthEast().lng
      }, ${mapbox2.map.getBounds().getSouthWest().lat} ${
        mapbox2.map.getBounds().getSouthWest().lng
      }, ${mapbox2.map.getBounds().getNorthWest().lat} ${
        mapbox2.map.getBounds().getNorthWest().lng
      }))`;

      this.$store.commit('updateNewProjectExtent', {
        polyText: polyText,
        treeCount: treeCount,
        includeTrees: this.$store.state.userStore.newProjectExtent.includeTrees,
      });
      this.showAddNewProject = false;
      this.$store.commit('updateLeftDrawer', {
        open: true,
        content: 'newproject',
      });

      //remove sending.
    },

    async updateTreesLayer() {
      this.$store.dispatch('getQAQCTrees', {
        qaqcId: this.activeQAQC[0].qaqc_id,
        projectId: this.activeQAQC[0].project_id,
      });
      var source = mapbox2.map.getSource('trees-data-qaqc');
      source.setData(this.treesData);
    },
    addTreeLayerAndSource() {
      mapbox2.map.addSource('trees-data-qaqc', {
        type: 'geojson',
        data: this.treesData,
        generateId: true,
      });
      mapbox2.map.addLayer({
        id: 'trees-layer',
        type: 'circle',
        source: 'trees-data-qaqc',
        paint: {
          'circle-radius': 8,
          'circle-color': [
            'match', // Use the match expression
            ['get', 'symbology'], // Get the value of the "type" property
            1,
            'gray', // If the "type" value is "Type A", use a red color
            0,
            '#ab47bc', // If the "type" value is "Type B", use a green color
            'green', // If the "type" value is,
            // 'case',
            // ['boolean', ['feature-state', 'hover'], false],
            // 'yellow',
            // 'red',
          ],
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff',
        },
      });
    },
    addMapControls() {
      // const that = this;
      // add map controls ************************************
      mapbox2.geolocate = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: true,
        showUserLocation: true,
      });
      // console.log('after add geolocate');

      mapbox2.map.addControl(mapbox2.geolocate, 'top-right');
      // super hacky code to show the users location via the blue GPS dot
      // the geolocate.trigger() was not working
      /*setTimeout(function () {
        const elementToClick = document.querySelector(
          '.mapboxgl-ctrl-geolocate'
        );
        if (elementToClick) {
          elementToClick.click();
          console.log('trigger geolocate');
        }
      }, 500);*/

      // let init = 0;

      // when a geolocate event occurs.
      mapbox2.geolocate.on('geolocate', (position) => {
        // You can access the user's current location here
        var userLocation = position.coords;
        var coordinates = position.coords;
        if (mapbox2.first === 'yes') {
          mapbox2.map.setZoom(18);
          mapbox2.first = 'no';
        }

        // if (init == 0) {
        //   that.map.flyTo({
        //     center: [coordinates.longitude, coordinates.latitude],
        //     zoom: 18,
        //   });
        // }
        // init = 1;

        //this passes the users location to the store
        this.$store.commit('updateUsersLocation', position);
      });
      //a custom function could check the last location and deterimin
      //if the distance meets the criteria for moving the dot or we can
      //choose to move the dot only on a threshold of accuracy.  We might
      //have to use a custom dot instead of the widget because I dont know
      //if we have control over the movement of the dot through the widget?
      //this needs more work and testing. Some other events below:
      /*
      // when a trackuserlocationstart or trackuserlocationend event occurs.
      this.geolocate.on('trackuserlocationstart', () => {
        console.log('A trackuserlocationstart event has occurred.');
      });
      this.geolocate.on('trackuserlocationend', () => {
        console.log('A trackuserlocationend event has occurred.');
      });*/

      // console.log(mapboxgl);
      // this.map.addControl(new mapboxgl.StyleSwitcherControl(), 'top-right');
    },
    flyToLatLon(obj) {
      mapbox2.map.flyTo({
        center: [obj.lon, obj.lat],
        essential: true, // this animation is considered essential with respect to prefers-reduced-motion
      });
      let geojson = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [obj.lon, obj.lat],
        },
        properties: {
          title: 'My Marker',
          description: 'This is a custom marker',
        },
      };
      this.clearHighlight();
      this.highlightPoint(geojson);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="mapbox-gl/dist/mapbox-gl.css"></style>
<style>
#map2 {
  height: calc(100vh - 124px);
}
.outlineToggle {
  border: 1px solid green;
}
.map-center-selector {
  position: absolute !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  font-size: 30px !important;
}
.add-tree-instructions {
  position: absolute;
  z-index: 100;
  left: 50%;
  top: 10px;
  width: 230px;
  margin-left: -115px;
}
.add-tree-buttons {
  position: absolute;
  z-index: 100;
  left: 50%;
  bottom: 10px;
  width: 192px;
  margin-left: -96px;
}
.tree-count-message {
  position: absolute;
  z-index: 100;
  left: 50%;
  top: 10px;
  width: 150px;
  margin-left: -75px;
}
.mapboxgl-ctrl-group button {
  height: 40px !important;
  width: 40px !important;
}
</style>
