<template>
  <q-list bordered class="rounded-borders bg-blue-grey-9">
    <q-form @submit="onSubmit" @reset="onReset">
      <!--tree care form-->
      <q-item class="text-body1 text-white bg-blue-grey-9 text-center">
        <q-item-section>
          <div class="q-mt-xs q-mb-none q-pb-none text-body2 text-bold">
            {{ selectedTree.tree_name }}
          </div>
          <div class="text-body2 q-mt-xs q-mb-none q-pb-none">
            {{ selectedTree.common_name }}
          </div>
          <div class="text-caption q-mt-none q-pt-none">
            {{ selectedTree.scientific_name }}
          </div>
        </q-item-section>
      </q-item>
      <q-card class="row q-ma-md q-pa-md" bordered>
        <q-input
          color="secondary"
          class="q-mb-sm"
          outlined
          square
          v-model="tDBH"
          label="DBH (in)"
          style="font-size: 16px; width: 100%"
        >
          <template v-slot:after>
            <q-btn
              round
              dense
              flat
              icon="sym_s_info"
              color="blue-grey-9"
              @click="
                this.$store.commit('updateShowHelp', {
                  url: '/help/DBH_Flowcharts.pdf',
                  filename: 'DBH_Flowcharts.pdf',
                })
              "
            /> </template
        ></q-input>
      </q-card>
      <!--tree care tasks-->
      <q-card class="row q-ma-md q-pa-md" bordered>
        <q-select
          filled
          v-model="tasks"
          :options="options"
          label="Stewardship Tasks"
          multiple
          emit-value
          map-options
          options-selected-class="text-deep-orange"
          style="width: 100%"
        >
          <template v-slot:option="{ itemProps, opt, selected, toggleOption }">
            <q-item v-bind="itemProps">
              <q-item-section>
                <q-item-label>{{ opt.label }}</q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-checkbox
                  :model-value="selected"
                  @update:model-value="toggleOption(opt)"
                />
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </q-card>
      <!--time spent-->
      <div class="q-ma-md bg-white">
        <q-input
          v-model="tTime"
          filled
          label="Time Spent (hours - decimal for partial hours)"
          style="font-size: 16px"
        />
      </div>
      <!--height-->
      <div class="q-mt-md q-mx-md q-mb-sm">
        <q-input
          outlined
          square
          v-model="tHeight"
          label="Tree Height (feet) - Optional"
          style="font-size: 16px"
          class="bg-white"
        />
      </div>
      <!--notes-->
      <div class="q-mt-md q-mx-md q-mb-sm">
        <q-input
          v-model="tNotes"
          autogrow
          outlined
          label="Notes"
          style="font-size: 16px"
          class="bg-white"
        />
      </div>
      <!--photos-->
      <!-- <q-item style="border-bottom: 1px solid lightgrey">
        <q-uploader
          url="http://localhost:4444/upload"
          multiple
          accept=".jpg, image/*"
          @rejected="onRejected"
          @added="handleFileAdded"
          style="width: 100%"
          class="shadow-1"
        >
          <template v-slot:header="scope">
            <div
              class="row no-wrap items-center q-pa-sm bg-blue-grey-1 text-primary"
            >
              <q-btn
                v-if="scope.uploadedFiles.length > 0"
                icon="done_all"
                @click="scope.removeUploadedFiles"
                round
                dense
                flat
              >
                <q-tooltip>Remove Uploaded Files</q-tooltip>
              </q-btn>
              <q-spinner v-if="scope.isUploading" class="q-uploader__spinner" />

              <div class="col text-left">
                <div class="q-uploader__title">Add Photos</div>
              </div>
              <q-btn
                v-if="scope.canAddFiles"
                type="a"
                icon="photo_camera"
                @click="scope.pickFiles"
                round
                dense
                flat
                size="lg"
              >
                <q-uploader-add-trigger />
                <q-tooltip>Pick Files</q-tooltip>
              </q-btn>
              <q-btn
                v-if="scope.canUpload"
                icon="cloud_upload"
                @click="uploadPhoto"
                round
                dense
                flat
                size="lg"
              >
                <q-tooltip>Upload Files</q-tooltip>
              </q-btn>

              <q-btn
                v-if="scope.isUploading"
                icon="clear"
                @click="scope.abort"
                round
                dense
                flat
              >
                <q-tooltip>Abort Upload</q-tooltip>
              </q-btn>
            </div>
          </template></q-uploader
        >
      </q-item> -->
      <!--submit /cancel-->
      <div class="q-mb-xl q-mt-md">
        <q-btn label="Submit" type="submit" color="primary" />
        <q-btn label="Reset" type="reset" color="white" flat class="q-ml-sm" />
      </div>
    </q-form>
  </q-list>
</template>

<script>
export default {
  name: 'FormTreeCare',
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    selectedTree() {
      return this.$store.state.treesStore.selectedTree;
    },
  },
  data() {
    return {
      treeInfoExpanded: true,
      careExpanded: true,
      tDBH: this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.dbh
        : null,
      tHeight: this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.treeheight
        : null,
      tNotes: this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.notes
        : null,
      tPhoto: '',
      tTime: this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.time_spent
        : null,
      tasks: this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.stewardship_tasks.split(
            ','
          )
        : [],
      options: [
        {
          label: 'Water',
          value: 'Water',
        },
        {
          label: 'Topsoil over roots',
          value: 'Topsoil over roots',
        },
        {
          label: 'Mulch over roots',
          value: 'Mulch over roots',
        },
        {
          label: 'Removal of refuse / liter',
          value: 'Removal of refuse / liter',
        },
        {
          label: 'Stake tree for safety / protection from cars',
          value: 'Stake tree for safety / protection from cars',
        },
        {
          label: 'Stake tree for lean',
          value: 'Stake tree for lean',
        },
        {
          label: 'Install tree fence / guard',
          value: 'Install tree fence / guard',
        },
        {
          label: 'Dead / diseased branch pruning',
          value: 'Dead / diseased branch pruning',
        },
        {
          label: 'Manage soil',
          value: 'Manage soil',
        },
        {
          label: 'Weed tree bed',
          value: 'Weed tree bed',
        },
        {
          label: 'Enlarge tree bed',
          value: 'Enlarge tree bed',
        },
        {
          label: 'Pruned suckers',
          value: 'Pruned suckers',
        },
        {
          label: 'Removed crossing branches',
          value: 'Removed crossing branches',
        },
        {
          label: 'Pruned major structural branches',
          value: 'Pruned major structural branches',
        },
        {
          label: 'Other - see notes',
          value: 'Other - see notes',
        },
      ],
    };
  },
  methods: {
    onRejected() {
      console.log('rejected');
    },
    handleFileAdded() {
      console.log('file added');
    },
    onSubmit() {
      this.handleResize();
      let obj = {
        visitId: this.$store.state.treesStore.selectedVisitDetails.visit_id,
        treeId: this.selectedTree.tree_id,
        projectId: this.selectedTree.project_id,
        inDbh: this.tDBH,
        inTreeheight: this.tHeight,
        inNotes: this.tNotes,
        timeSpent: this.tTime,
        inPhoto: this.tPhoto,
        stewardshipTasks: this.tasks.toString(),
      };
      if (this.$store.state.treesStore.editTreeMode) {
        this.$store.dispatch('editTreeCare', obj);
      } else {
        this.$store.dispatch('insertTreeCare', obj);
      }
    },
    onReset() {
      this.handleResize();
      this.tTasks = [];
      this.tDBH = this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.dbh
        : null;
      this.tHeight = this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.treeheight
        : null;
      this.tNotes = this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.notes
        : null;
      this.tTime = this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.time_spent
        : null;
      this.tasks = this.$store.state.treesStore.editTreeMode
        ? this.$store.state.treesStore.selectedVisitDetails.stewardship_tasks
        : [];
    },
    //what this does is reset the size of the viewport.  This handles the issue on mobile
    //where the browser resets the size of the viewport in order to move the input up above
    //the virtual keyboard.  Setting the font size to 16px prevents it froom zooming.
    handleResize() {
      const currentViewportHeight = window.visualViewport.height;
      const heightDifference =
        this.originalViewportHeight - currentViewportHeight;
      window.scrollTo(0, window.pageYOffset + heightDifference);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
