<template>
  <q-card-actions align="left">
    <q-btn
      flat
      icon="sym_s_arrow_back"
      label=""
      color="primary"
      @click="
        $store.commit('updateLeftDrawer', { open: false });
        this.$store.commit('updateEditTreeMode', false);
      "
    />
    <q-chip size="lg" color="white" class="text-primary"
      >Create New Project</q-chip
    >
  </q-card-actions>

  <q-form
    class="q-ma-md bg-white text-left"
    @submit="onSubmit"
    @reset="onReset"
  >
    <q-input
      square
      outlined
      v-model="projectName"
      label="Project Name*"
      class="q-mb-none q-pb-sm"
      lazy-rules
      :rules="[(val) => !!val]"
    />
    <q-input
      square
      outlined
      v-model="inDescription"
      type="textarea"
      label="Project Description"
      class="q-mb-sm"
      autogrow
    />
    <q-input
      square
      outlined
      v-model="inExtent"
      type="textarea"
      label="Project Extent"
      class="q-mb-sm"
      autogrow
    />
    <q-input
      square
      outlined
      v-model="inObjectives"
      type="textarea"
      label="Project Objectives"
      class="q-mb-sm"
      autogrow
    />
    <q-input square outlined v-model="inCity" label="City" class="q-mb-md" />
    <div>
      <q-checkbox
        size=""
        true-value="1"
        false-value="0"
        v-model="this.$store.state.userStore.newProjectExtent.includeTrees"
      ></q-checkbox>
      include
      {{
        this.$store.state.userStore.newProjectExtent.treeCount == 3000
          ? '3000+'
          : this.$store.state.userStore.newProjectExtent.treeCount
      }}
      trees in extent
    </div>
    <div>
      <q-checkbox
        size=""
        true-value="1"
        false-value="0"
        v-model="this.acceptingUsers"
      ></q-checkbox>
      HTHC members will be allowed to make a request to join the project
    </div>

    <p class="text-subtitle-2 q-mb-none q-pb-none q-mt-md">
      Show project in data collection tool?
    </p>
    <p class="text-caption text-grey-8 q-mb-none">
      If yes, the project trees will be displayed in the field collection tool
      for every user in the project.
    </p>
    <q-radio name="fieldtool" v-model="inVisible" val="1" label="Yes" />
    <q-radio name="fieldtool" v-model="inVisible" val="0" label="No" />
    <div v-if="inVisible == 1">
      <p class="text-subtitle-2 q-mb-none q-pb-none q-mt-md">
        Select activities available to users in the field collection tool
      </p>
      <p class="text-caption text-grey-8 q-mb-none">
        Only the checked activities will be available in the field collection
        tool for this project.
      </p>
      <q-checkbox
        name="fieldtool"
        v-model="healthCheck"
        true-value="1"
        false-value="0"
        label="Health Check"
      />
      <q-checkbox
        name="fieldtool"
        v-model="pestDetection"
        true-value="1"
        false-value="0"
        label="Pest Detection"
      />
      <q-checkbox
        name="fieldtool"
        v-model="treeCare"
        true-value="1"
        false-value="0"
        label="Tree Care"
      />
    </div>
    <!--submit /cancel-->
    <div class="q-mb-xl q-mt-md">
      <q-btn label="Submit" type="submit" color="primary" />
      <q-btn label="Reset" type="reset" color="white" flat class="q-ml-sm" />
    </div>
  </q-form>
</template>

<script>
export default {
  name: 'FormAddProject',

  data() {
    return {
      inCity: null,
      projectName: null,
      inDescription: null,
      inExtent: null,
      inObjectives: null,
      healthCheck: '1',
      treeCare: '1',
      pestDetection: '1',
      inVisible: '1',
      acceptingUsers: '1',
    };
  },
  components: {},
  methods: {
    onSubmit() {
      let obj = {
        inCity: this.inCity,
        projectName: this.projectName,
        inDescription: this.inDescription,
        inExtent: this.inExtent,
        inObjectives: this.inObjectives,
        healthCheck: parseInt(this.healthCheck),
        treeCare: parseInt(this.treeCare),
        pestDetection: parseInt(this.pestDetection),
        inVisible: parseInt(this.inVisible),
        polyText: this.$store.state.userStore.newProjectExtent.polyText,
        includeTrees: parseInt(
          this.$store.state.userStore.newProjectExtent.includeTrees
        ),
        acceptingUsers: parseInt(this.acceptingUsers),
      };
      this.$store.dispatch('createNewProject', obj);
    },
    onReset() {
      this.inCity = null;
      this.projectName = null;
      this.inDescription = null;
      this.inExtent = null;
      this.inObjectives = null;
      this.healthCheck = true;
      this.treeCare = true;
      this.pestDetection = true;
      this.inVisible = null;
      this.acceptingUsers = null;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
