<template>
  <div class="q-pa-md">
    <!--comment-->
    <q-carousel
      animated
      v-model="slide"
      navigation
      infinite
      fullscreen
      swipeable
    >
      <q-carousel-slide
        v-for="(slide, index) in this.introSlides"
        :key="index"
        :name="slide.name"
        :img-src="slide.image"
        :draggable="false"
      >
        <div class="absolute-center custom-caption">
          <div class="text-h3">{{ slide.title }}</div>
          <div class="text-subtitle1">{{ slide.text }}</div>
          <q-toolbar v-if="slide.name < 7">
            <q-btn
              flat
              size="lg"
              color="white"
              @click="this.$store.dispatch('loginAtAppLoad')"
              >skip</q-btn
            >
            <q-space></q-space>
            <q-btn
              flat
              icon="arrow_forward"
              size="lg"
              color="white"
              @click="this.slide = this.slide + 1"
            ></q-btn>
          </q-toolbar>
          <div v-if="slide.name == 7">
            <q-btn
              size="lg"
              color="primary"
              @click="this.$store.dispatch('loginAtAppLoad')"
              >Go to app</q-btn
            >
          </div>
        </div>
      </q-carousel-slide>
    </q-carousel>
  </div>
</template>
<script>
export default {
  name: 'Intro',
  data() {
    return {
      slide: 1,
      draggable: false,
      introSlides: [
        {
          title: 'Help Trees Help You',
          text: 'Healthy trees create healthy communities. Learn how you can support trees where you live, work, and play...',
          image: '/intro/intro1.png',
          name: 1,
        },
        {
          title: 'Plant a Tree',
          text: 'More trees mean cleaner air, fresher water, and safer streets...',
          image: '/intro/intro2.png',
          name: 2,
        },
        {
          title: 'Adopt a Tree',
          text: 'Nurture the trees – young and old – in your community...',
          image: '/intro/intro3.png',
          name: 3,
        },
        {
          title: 'Prune a Tree',
          text: 'Careful pruning can lead to a long and healthy life for your trees...',
          image: '/intro/intro4.png',
          name: 4,
        },
        {
          title: 'Monitor a Tree',
          text: 'Conduct regular health checkups for your tree ...',
          image: '/intro/intro5.png',
          name: 5,
        },
        {
          title: 'Check a Tree',
          text: 'Be the first to see signs of harmful insects in your tree ...',
          image: '/intro/intro6.png',
          name: 6,
        },
        {
          title: 'Connect with Friends',
          text: 'Be part of a network of tree stewards in your area and across the country.',
          image: '/intro/intro7.png',
          name: 7,
        },
      ],
    };
  },
};
</script>
<style scoped>
.custom-caption {
  text-align: center;
  padding: 12px;
  color: white;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
}
</style>
