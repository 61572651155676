<template>
  <q-card-actions align="left">
    <q-btn
      flat
      icon="sym_s_arrow_back"
      label=""
      color="primary"
      @click="
        $store.dispatch('getUsersProjects');
        $store.commit('updateProjectsTab', 'myprojects');
        $store.commit('updateLeftDrawer', { open: false });
      "
    />
    <q-chip size="lg" color="white" class="text-primary"
      >Project Parcticipation Requests</q-chip
    >
  </q-card-actions>
  <q-list class="q-ma-md">
    <q-item v-if="this.$store.state.userStore.memberRequests.length == 0">
      <q-item-section class="col text-left">
        <q-item-label>No more requests for this project</q-item-label>
      </q-item-section>
    </q-item>
    <q-item
      v-else
      v-for="(member, key) in this.$store.state.userStore.memberRequests"
      :key="key"
      style="border-bottom: 1px solid lightgrey"
    >
      <q-item-section class="col text-left">
        <q-item-label class="text-bold">
          {{ member.requester_name }}
        </q-item-label>
        <q-item-label class="text-caption">
          {{ member.requester_email }}
        </q-item-label>
        <q-item-label>
          <q-radio
            v-model="member.role"
            label="Participant"
            val="3"
            color="primary"
          ></q-radio>
          <q-radio
            v-model="member.role"
            label="Project Leader"
            val="2"
          ></q-radio>
          <q-item-label>
            <q-btn
              unelevated
              color="secondary"
              icon="check"
              class="q-mr-md"
              @click="approveMembers(member)"
              label="approve"
              size="md"
            ></q-btn>
            <q-btn
              padding="sm"
              color="red"
              flat
              label="deny"
              @click="
                this.$store.dispatch('denyMember', {
                  projectId: member.project_id,
                  requestId: member.request_id,
                })
              "
            ></q-btn
          ></q-item-label>
        </q-item-label>
      </q-item-section>
    </q-item>
  </q-list>
</template>

<script>
export default {
  name: 'approvals',
  mounted() {},
  data() {
    return {
      role: 3,
    };
  },
  methods: {
    approveMembers(member) {
      let role = parseInt(member.role);
      if (!member.role) {
        role = 3;
      }
      this.$store.dispatch('approveMember', {
        projectId: member.project_id,
        requestId: member.request_id,
        roleId: role,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.semi-bold {
  font-weight: 600;
}
</style>
