<template>
  <!--show full screen image-->
  <div v-if="imageDisplay">
    <div>
      <q-btn
        style="position: absolute; margin: 10px"
        round
        color="primary"
        icon="sym_s_arrow_back"
        @click="imageDisplay = false"
      />
      <img
        style="max-width: 100vw; max-height: 100vh; object-fit: contain"
        :src="imageDisplay"
      />
    </div>
  </div>
  <!--show tree home page-->
  <div v-else>
    <q-card-actions align="left">
      <q-btn
        flat
        icon="sym_s_arrow_back"
        label=""
        color="primary"
        @click="goBack()" />
      <q-chip
        size="lg"
        color="white"
        v-if="this.active == 'Tree Home'"
        class="text-primary"
        >Tree home</q-chip
      >
      <q-chip
        size="lg"
        color="white"
        class="text-primary"
        v-if="this.active !== 'Tree Home'"
        >Tree home: &nbsp;<q-icon
          v-if="this.active == 'Health Check'"
          name="sym_s_health_and_safety"
        ></q-icon>
        <q-icon
          v-if="this.active == 'Tree Care'"
          name="sym_s_water_drop"
        ></q-icon>
        <q-icon
          v-if="this.active == 'Pest Detection'"
          name="sym_s_pest_control"
        ></q-icon>
        {{ this.active.toLowerCase() }} </q-chip
      ><q-space></q-space>
      <div
        class="text-caption text-blue-grey-9 q-mr-sm text-left"
        style="line-height: 100%"
        flat
        no-caps
        color="primary"
      >
        Project name: <br />{{
          this.selectedTree.project_name ? this.selectedTree.project_name : '--'
        }}
      </div>

      <p></p
    ></q-card-actions>

    <!--tree home page is the default view-->
    <div class="q-pa-md text-left" v-if="this.active == 'Tree Home'">
      <q-list class="shadow-2">
        <q-item v-ripple style="border-bottom: 1px solid lightgrey">
          <q-item-section
            avatar
            class="flex self-center highlight-and-fade q-pb-none q-pt-xs"
          >
            <div class="text-primary">
              <q-btn
                v-if="selectedTree.z_score"
                round
                @click="noZscore(selectedTree)"
              >
                <q-knob
                  readonly
                  v-model="selectedTree.z_score"
                  :max="10"
                  show-value
                  size="45px"
                  :thickness="0.22"
                  :color="
                    selectedTree.z_score < 4
                      ? 'green'
                      : selectedTree.z_score > 3 && selectedTree.z_score < 8
                      ? 'orange'
                      : 'red'
                  "
                  :class="{
                    'text-green': selectedTree.z_score < 4,
                    'text-orange':
                      selectedTree.z_score > 3 && selectedTree.z_score < 8,
                    'text-red': selectedTree.z_score > 7,
                  }"
                  track-color="grey-4"
                  font-size="16px"
                />
              </q-btn>
              <q-btn
                v-else
                round
                size="27px"
                padding="0px"
                icon="far fa-circle-question"
                style="height: 45px; width: 45px; color: #ccc"
                @click="noZscore(selectedTree)"
              />
            </div>
          </q-item-section>

          <q-item-section>
            <div class="q-mt-xs q-mb-none q-pb-none text-body2 text-bold">
              <q-icon
                v-if="selectedTree.created_tree"
                name="sym_s_person"
                size="xs"
                color="grey-9"
                class="q-mr-sm"
              ></q-icon>
              {{ selectedTree.tree_name }}
            </div>
            <div class="text-body2 q-mt-xs q-mb-none q-pb-none">
              {{ selectedTree.common_name }}
            </div>
            <div class="text-caption text-grey-9 q-mt-none q-pt-none">
              {{ selectedTree.scientific_name }}
            </div>
          </q-item-section>

          <q-item-section avatar>
            <div class="text-grey-8 q-gutter-xs">
              <q-btn
                color="primary"
                padding="xs"
                size="lg"
                flat
                round
                icon="sym_s_home_pin"
                @click="goToLatLon()"
              />
            </div>
          </q-item-section>
        </q-item>
        <q-item style="border-bottom: 1px solid lightgrey">
          <q-btn
            v-if="selectedTree.created_tree"
            icon="edit"
            color="primary"
            padding="sm"
            flat
            @click="this.editTree()"
            size="sm"
            label="Edit Info"
          >
          </q-btn>
          <q-btn
            v-if="selectedTree.created_tree"
            icon="far fa-circle-xmark"
            color="primary"
            padding="sm"
            flat
            @click="this.moveTree()"
            size="sm"
            label="Edit location"
          >
          </q-btn>
        </q-item>

        <q-dialog v-model="showHealthCheckCounts">
          <health-check-count
            :speciesHealthCheck="healthcheckCounts[0].species_health_checks"
            :treeHealthCheck="healthcheckCounts[0].tree_health_checks"
          ></health-check-count>
        </q-dialog>

        <q-item style="border-bottom: 1px solid lightgrey">
          <q-item-section>
            <!--div class="text-caption text-grey">
              <q-icon
                v-if="selectedTree.created_tree"
                name="sym_s_person"
                size="xs"
                color="grey-9"
                class="q-mr-sm"
              ></q-icon>

              DBH:
              {{ selectedTree.dbh ? selectedTree.dbh + ' in' : 'not set' }}
            </div-->
            <div class="row">
              <div
                class="text-caption text-blue-grey-9 col-2"
                style="border-right: 1px solid lightgrey"
              >
                Dbh: <br />
                {{ selectedTree.dbh ? selectedTree.dbh + ' in' : 'not set' }}
              </div>
              <div class="text-caption text-blue-grey-9 col-9 q-ml-md">
                Notes:<br />
                {{ selectedTree.notes ? selectedTree.notes : '--' }}
              </div>
            </div>
          </q-item-section>
        </q-item>
        <q-item
          v-if="selectedTree.project_id"
          v-ripple
          style="border-bottom: 1px solid lightgrey"
        >
          <q-btn
            size="md"
            color="primary"
            dark
            class="q-mr-xs"
            @click="this.active = 'Health Check'"
            v-if="selectedTree.health_check == 1"
            >Health Check</q-btn
          >
          <q-btn
            size="md"
            color="primary"
            class="q-mr-xs"
            @click="this.active = 'Tree Care'"
            v-if="selectedTree.tree_care == 1"
            >Tree Care</q-btn
          >
          <q-btn
            size="md"
            color="primary"
            @click="this.active = 'Pest Detection'"
            v-if="selectedTree.pest_detection == 1"
            >Pest Detection</q-btn
          >
        </q-item>
        <q-item v-ripple style="border-bottom: 1px solid lightgrey">
          <q-btn
            outline
            size="md"
            no-caps
            color="primary"
            @click="this.showProjects = true"
            v-if="selectedTree.project_id"
          >
            Add Tree to Another Project</q-btn
          >
          <q-btn
            outline
            size="md"
            no-caps
            color="primary"
            @click="this.showProjects = true"
            v-else
          >
            Add Tree to Project</q-btn
          >
          <q-select
            outlined
            class="q-ml-xs"
            style="width: 50%"
            dense
            label="Select a project"
            v-model="selectedProject"
            :options="projects"
            v-if="this.showProjects == true"
            option-label="project_name"
            @update:model-value="
              this.$store.dispatch('addTreeToProject', {
                projectId: selectedProject.project_id,
                treeId: selectedTree.tree_id,
              })
            "
          >
          </q-select>
        </q-item>
        <!--these are icon buttons-->
        <div class="row" style="padding: 10px">
          <div
            class="photo-thumbnail-wrapper"
            v-for="photoUrl in selectedTree.photosUrlArray"
            :key="photoUrl"
          >
            <!--these are icon buttons-->
            <div v-if="photoDeleteId != photoUrl.id" class="thumbnail-toolbar">
              <!-- <q-icon name="delete" size="30px" color="white"></q-icon> -->
              <q-btn
                style="
                  margin: 5px 0 0 5px;
                  background-color: rgba(0, 0, 0, 0.4);
                "
                v-if="selectedTree.created_tree"
                text-color="white"
                round
                icon="delete"
                size="sm"
                @click="photoDeleteId = photoUrl.id"
              />
              <q-space v-if="!selectedTree.created_tree"></q-space>
              <q-btn
                style="
                  margin: 5px 5px 0 0;
                  background-color: rgba(0, 0, 0, 0.4);
                "
                text-color="white"
                round
                size="sm"
                icon="open_in_full"
                @click="imageDisplay = photoUrl.url"
              />
            </div>
            <!--these are word buttons-->
            <div>
              <div
                style="
                  position: absolute;
                  color: white;
                  bottom: 30px;
                  width: 100%;
                  text-align: center;
                "
                v-if="photoDeleteId == photoUrl.id"
              >
                <q-btn
                  size="md"
                  color="primary"
                  dark
                  class="q-mb-md"
                  @click="photoDeleteId = null"
                  >Cancel</q-btn
                >
                <q-btn size="md" color="red" dark @click="deletePhoto(photoUrl)"
                  >Delete</q-btn
                >
              </div>
              <img
                style="max-width: 140px; max-height: 180px"
                :src="photoUrl.url"
                alt=""
              />
            </div>
          </div>
          <br />

          <!-- <span class="material-symbols-outlined"> open_in_new </span> -->
        </div>

        <p class="text-body2 q-ma-md text-blue-grey-6">Recent Visits</p>
        <q-expansion-item
          group="visits"
          class="text-blue-grey-6"
          style="border-bottom: 1px solid lightgrey"
          v-for="(visit, key) in this.selectedTreeVisits"
          :key="key"
          @click="getVisitDetails(visit.visit_id, visit.visit_name)"
          ><template v-slot:header>
            <q-item-section avatar>
              <q-icon
                v-if="visit.visit_name == 'Pest Detection'"
                color="blue-grey-6"
                name="sym_s_pest_control"
              />
              <q-icon
                v-if="visit.visit_name == 'Health Check'"
                color="blue-grey-6"
                name="sym_s_health_and_safety"
              />
              <q-icon
                v-if="visit.visit_name == 'Tree Care'"
                color="blue-grey-6"
                name="sym_s_water_drop"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label
                class="text-blue-grey-6 q-mt-none q-pt-none text-overline"
              >
                <b> {{ visit.visit_name.toUpperCase() }}</b>
              </q-item-label>
              <q-item-label
                class="text-blue-grey-6 text-caption q-mt-none q-pt-none"
              >
                {{ parseDate(visit.visit_date) }}, by project

                {{ visit.project_name }}
              </q-item-label>
            </q-item-section>
          </template>
          <q-card bordered>
            <q-card-section>
              <q-btn
                v-if="
                  this.$store.state.treesStore.selectedVisitDetails
                    .created_visit == 'yes'
                "
                flat
                icon="edit"
                color="primary"
                size="sm"
                style="width: 100%"
                @click="editTreeHealthForms(visit.visit_name)"
                >&nbsp;edit record</q-btn
              >
              <q-markup-table flat dense wrap-cells>
                <tbody>
                  <tr
                    v-for="(value, propertyName, index) in this
                      .selectedVisitDetails"
                    :key="index"
                  >
                    <td class="text-left">{{ propertyName }}</td>
                    <td class="text-right">{{ value }}</td>
                  </tr>
                </tbody>
              </q-markup-table>
            </q-card-section>
          </q-card>
        </q-expansion-item>
        <!--photo uploader-->
        <q-item
          style="border-bottom: 1px solid lightgrey"
          v-if="selectedTree.project_id"
        >
          <q-uploader
            ref="myUploader"
            url="http://localhost:4444/upload"
            multiple
            accept=".jpg, image/*"
            @rejected="onRejected"
            @added="handleFileAdded"
            style="width: 100%"
            class="shadow-1"
          >
            <template v-slot:header="scope">
              <div
                class="row no-wrap items-center q-pa-sm bg-blue-grey-1 text-primary"
              >
                <q-btn
                  v-if="scope.uploadedFiles.length > 0"
                  icon="done_all"
                  @click="scope.removeUploadedFiles"
                  round
                  dense
                  flat
                >
                  <q-tooltip>Remove Uploaded Files</q-tooltip>
                </q-btn>
                <q-spinner
                  v-if="scope.isUploading"
                  class="q-uploader__spinner"
                />

                <div class="col"></div>
                <q-btn
                  v-if="scope.canAddFiles"
                  type="a"
                  icon="photo_camera"
                  @click="scope.pickFiles"
                  round
                  dense
                  flat
                  size="lg"
                  label="Take Photo(s)"
                  class="q-mr-sm"
                >
                  <q-uploader-add-trigger />
                  <q-tooltip>Pick Files</q-tooltip>
                </q-btn>
                <q-btn
                  v-if="scope.canUpload"
                  icon="cloud_upload"
                  @click="uploadPhoto"
                  round
                  dense
                  flat
                  size="lg"
                  label="Save Photo(s)"
                >
                  <q-tooltip>Upload Files</q-tooltip>
                </q-btn>

                <q-btn
                  v-if="scope.isUploading"
                  icon="clear"
                  @click="scope.abort"
                  round
                  dense
                  flat
                >
                  <q-tooltip>Abort Upload</q-tooltip>
                </q-btn>
              </div>
            </template></q-uploader
          >
        </q-item>
      </q-list>
    </div>
    <!-- these forms will hide/show depending on which is selected-->
    <div v-if="this.active == 'Pest Detection'">
      <form-pest-detection></form-pest-detection>
    </div>
    <div v-if="this.active == 'Health Check'">
      <form-health-check></form-health-check>
    </div>
    <div v-if="this.active == 'Tree Care'">
      <form-tree-care></form-tree-care>
    </div>
    <!--if full screen photo is selected TO DO: PUT HERE-->
  </div>
</template>

<script>
import FormHealthCheck from './FormHealthCheck.vue';
import FormTreeCare from './FormTreeCare.vue';
import FormPestDetection from './FormPestDetection.vue';
import HealthCheckCount from './HealthCheckCount.vue';

export default {
  name: 'DialogTreeHome',
  components: {
    FormHealthCheck,
    FormTreeCare,
    FormPestDetection,
    HealthCheckCount,
  },
  mounted() {
    this.$store.dispatch('getVisitsByTreeId', this.selectedTree.tree_id);
    this.$store.dispatch('getUsersProjects');
  },
  unmounted() {
    this.$store.commit('updateSelectedTreeVisits', '');
    this.$store.commit('updateSelectedVisitDetails', '');
  },
  computed: {
    projects() {
      return this.$store.state.userStore.usersProjects;
    },
    healthcheckCounts() {
      return this.$store.state.treesStore.healthcheckCounts;
    },
    selectedTree() {
      return this.$store.state.treesStore.selectedTree;
    },
    selectedTreeVisits() {
      return this.$store.state.treesStore.selectedTreeVisits;
    },
    selectedVisitDetails() {
      let obj = this.$store.state.treesStore.selectedVisitDetails;
      let newObj = '';
      console.log(obj);
      if (this.visitName == 'Health Check') {
        newObj = {
          'DHB (in)': obj.dbh,
          Notes: obj.notes,
          'Fine twig dieback': obj.fine_twig_dieback,
          'Leaf discoloration': obj.leaf_discoloration,
          'Leaf defoliation': obj.leaf_defoliation,
          'Crown light exposure': obj.crown_light_exposure,
          'Crown vigor': obj.crown_vigor,
          'Crown density': obj.crown_density_trans
            ? this.crownTransparecyOptions[
                parseInt(obj.crown_density_trans) - 1
              ].label
            : null,
        };
      } else if (this.visitName == 'Pest Detection') {
        newObj = {
          'DHB (in)': obj.dbh,
          Notes: obj.notes,
          'Exit holes': obj.exit_holes,
          Exudation: obj.exudation,
          Eggs: obj.eggs,
          'Adult insect or larva': obj.adult_insect_or_larva_appearance,
          'Fruit damage': obj.fruit_damage,
          'Holes in leaves': obj.holes_in_leaves,
          Frass: obj.frass,
          'Epicormic sprouts': obj.epicormic_sprouts,
          Blonding: obj.blonding,
          'S shaped galleries': obj.s_shaped_galleries,
          'Bark fissures': obj.bark_fissures,
          'Dead bark': obj.dead_bark,
          Galls: obj.galls,
          'Dead twigs': obj.dead_twigs,
          'Discoloration deciduous': obj.discoloration_deciduous,
          'Discoloration conifer': obj.discoloration_conifer,
          'Wilted leaves': obj.wilted_leaves,
          'Premature leaf loss': obj.premature_leaf_loss,
        };
      } else if (this.visitName == 'Tree Care') {
        newObj = {
          'DHB (in)': obj.dbh,
          Notes: obj.notes,
          'Stewardship tasks': obj.stewardship_tasks,
          'Time spent (hrs)': obj.time_spent,
        };
      }

      return newObj;
    },
  },
  data() {
    return {
      active: 'Tree Home',
      visitName: '',
      healthIndex: 0,
      fileToBeUploaded: null,
      showProjects: false,
      selectedProject: '',
      showHealthCheckCounts: false,
      imageDisplay: false,
      photoDelete: false,
      photoDeleteId: null,
      crownTransparecyOptions: [
        {
          label: '5%',
          value: 1,
          img: '/help/health_check/crown/5.png',
        },
        {
          label: '15%',
          value: 2,
          img: '/help/health_check/crown/15.png',
        },
        {
          label: '25%',
          value: 3,
          img: '/help/health_check/crown/25.png',
        },
        {
          label: '35%',
          value: 4,
          img: '/help/health_check/crown/35.png',
        },
        {
          label: '45%',
          value: 5,
          img: '/help/health_check/crown/45.png',
        },
        {
          label: '55%',
          value: 6,
          img: '/help/health_check/crown/55.png',
        },
        {
          label: '65%',
          value: 7,
          img: '/help/health_check/crown/65.png',
        },
        {
          label: '75%',
          value: 8,
          img: '/help/health_check/crown/75.png',
        },
        {
          label: '85%',
          value: 9,
          img: '/help/health_check/crown/85.png',
        },
        {
          label: '95%',
          value: 10,
          img: '/help/health_check/crown/95.png',
        },
      ],
    };
  },
  methods: {
    async deletePhoto(params) {
      await this.$store.dispatch('deletePhotoTree', params);
      // this.photoDeleteId = null;
    },
    parseDate(unixDate) {
      //let unix_timestamp = 1549312452;
      let unix_timestamp = unixDate;
      let date = new Date(unix_timestamp * 1000).toLocaleString();
      return date;
    },
    getVisitDetails(visit_id, visit_name) {
      this.visitName = visit_name;
      this.$store.dispatch('getVisitDetails', visit_id);
    },
    async goToLatLon() {
      await this.$store.commit('updateGoToLatLon', {
        lat: this.selectedTree.latitude,
        lon: this.selectedTree.longitude,
      });
      await this.$store.commit('updateSelectedView', 'map');
      await this.$store.commit('updateLeftDrawer', { open: false });
    },
    async editTree() {
      await this.$store.commit('updateEditTreeMode', true);
      await this.$store.commit('updateLeftDrawer', {
        open: true,
        content: 'addtree',
      });
    },
    async moveTree() {
      //trigger move tree
      await this.$store.commit('updateMoveTreeMode', true);
      //go to map
      await this.$store.commit('updateGoToLatLon', {
        lat: this.selectedTree.latitude,
        lon: this.selectedTree.longitude,
      });
      await this.$store.commit('updateSelectedView', 'map');
      await this.$store.commit('updateLeftDrawer', { open: false });
    },
    async editTreeHealthForms(form) {
      await this.$store.commit('updateEditTreeMode', true);
      this.active = form;
    },
    handleFileAdded(file) {
      this.fileToBeUploaded = file;
    },
    uploadPhoto(evt, photo) {
      this.$store.dispatch('addPhotoToTree', this.fileToBeUploaded[0]);
      this.$refs.myUploader.reset(); // remove photo file from uploader
    },
    goBack() {
      this.$store.commit('updateEditTreeMode', false);
      if (this.active == 'Tree Home') {
        this.$store.commit('updateLeftDrawer', { open: false });
      } else {
        this.active = 'Tree Home';
      }
    },
    onRejected() {},
    async noZscore(tree) {
      await this.$store.dispatch('getHealthcheckCounts', [
        tree.sub_id,
        tree.tree_id,
      ]);
      this.showHealthCheckCounts = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.semi-bold {
  font-weight: 600;
}
tr {
  color: #37474f;
}
.photo-thumbnail-wrapper {
  position: relative;
  margin-left: 20px;
}
.thumbnail-toolbar {
  padding: 4px 3px 0 0px;
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>
